div.locations-continer{
    background-color: white;
    margin: 25px 0px;
    padding: 30px;
    border-radius: 4px;
}
div.locations-continer h2{
    font: 20px;
    font-weight: 500;
    color:#04063D;
    margin-bottom: 4px;
}
div.single-location{
    border: 1px solid #D5D0FD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 13px;
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
}
div.loc-flag{
    height: 40px;
    width: 40px;
    border-radius: 100px;
    background-color: yellowgreen;
}
div.loc-contents p:first-child{
    margin: 0px;
    font-size: 15px;
    font-weight: 500;
    color:#04063D;
}
div.loc-contents p:last-child{
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
    color:#667085;
}


@media only screen and (max-width: 600px) { 
/*Mobile screen displays*/






}




@media only screen and (max-width: 961px) and (min-width:600px){ 
/*Medium screen displays*/





}




@media only screen and (min-width:961px)and (max-width:1337px){
/*Large screen displays*/





}

