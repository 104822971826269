.sixthView {
    position: relative;
    max-width: 1216px;
    margin: 0 auto;
    background-image: url('../../../assets/backgroundPath4.svg');
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: 30% 100%;
    overflow-y: visible;
    overflow-x: hidden;
    padding: 100px 15px;
}

.faq-search-container {
    text-align: center;
}

.faq-search-container p {
    color: #667085;
}

.heading-T {
    margin: 1vh 0vh;
}

.searchBox {
    margin: 40px auto 0;
}

.searchBox input[type="text"] {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    border-color: #cbcbdb;
}

.searchIcon {
    color: #667085;
    margin: 0px;
    padding: 0px;
    position: relative;
    font-size: 30px;
    top: 10px;
    right: 3px;
}

.input-box-root {
    margin: 10px;

}

.input-box-root:focus-within {
    border-bottom: 1px solid #667085;
}

.search-answers-container {
    margin: auto;
    margin-top: 42px;
    width: fit-content;
}

.search-solution-icon {
    color: #5a48fb;
    background-color: #e6e7ff;
    padding: 15px;
    border-radius: 1000px;
    padding-top: auto;
    display: flex;
    justify-content: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.question {
    text-align: center;
    font-size: 20px;
    color: #04063D;
    font-weight: 500;
    font-family: 'Inter';
    align-self: stretch;
    margin: 1vh 0px;
}

p.answer {
    color: #667085;
    text-align: center;
    font-family: 'Inter';
    margin-top: 5px;
}

.blur-13 {
    background: #5A48FA;
    top: 40%;
    left: 40%;

}

.searchBox input {
    background-color: white !important;
    color: #04063D !important;
}

.search-answers-container {
    margin-top: 60px
}

.search-answers-container>div>.css-a5rdam-MuiGrid-root {
    margin: 0;
    width: 100%;
    flex-wrap: wrap;
}

.search-answers-container>div>.css-a5rdam-MuiGrid-root>.css-1tppydz-MuiGrid-root {
    width: calc(33.33% - 30px);
    padding: 0;
    flex-basis: auto;
    margin: 0 15px 50px
}

@media (max-width:1024px){
    .search-answers-container > div > .css-a5rdam-MuiGrid-root > .css-1tppydz-MuiGrid-root {
        width: calc(50% - 30px);
    }
}

@media
 (max-width: 600px) {
    /*Mobile screen displays*/

    .faq-search-container {
        margin: auto;
        margin-bottom: 42px;
        text-align: center;

    }

    .search-answers-container {
        margin: 1vh;
    }

    .search-solution-icon {
        margin: auto;
        margin-bottom: 3vh;
    }

    .help-indicate {
        max-width: 200px;
        margin: auto;
        margin-top: -10px;
    }

    .single-faq {

        max-width: 290px;
        margin: auto;
    }

    .answer {
        font-size: 16px;
        line-height: 24px;

    }

    .blur-13 {
        display: none;
    }

    .blur-14 {
        display: none;
    }

    .blur-15 {
        display: none;
    }
}



@media (max-width:767px) {
    .sixthView {
        padding: 60px 15px;
    }
}

