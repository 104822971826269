.FithView{
    max-width: 1220px;
    margin: 0 auto;
    height: fit-content;
    width: 100%;
    overflow: visible;
}
.halfCircle{
    background: url('../../../assets/halfCircle.svg') center;
    background-repeat: no-repeat;
    padding: 20px 0 50px 50px;
}

.titleBox{
max-width: 445px;
}
.titleBox h1{
    margin: 0px;
}
.gridStyle{
    width: 90% !important;
    margin: 5vh auto !important;
}
.iconsDiv{
    display: flex;
    justify-content: space-around;
    max-width: 540px;
}
.iconsDiv img{
    width: 70px;
    height: 70px;
    max-width: 75px;
    max-height: 75px;
    margin-top: 40px;
}
.iconsDiv img:hover{
    transform: rotate(360deg);
    scale: 1.5;
    transition: 2s;
}
.iconsDiv2{
    display: flex;
    justify-content: space-between;
    width: fit-content;
    margin: auto;
    gap: 60px;
    margin-top: 3vh;
    height: fit-content;
}
.iconsDiv2 img{
    width: 120px;
    height: 120px;
    margin: -10px;
    padding: 0px;
    /* max-width: 75px;
    max-height: 75px; */
}
.iconsDiv2 img:hover{
    transform: rotate(360deg);
    scale: 1.5;
    transition: all 2s;
    cursor: pointer;
}

.testimonials-Box{
    background-color: #04063D;
    width: 100%;
    margin: 0px;
    padding: 100px 15px;
    position: relative;
}
.testimonials-Box h1{
    margin: 0px;
}
.testimonials-Box-title{
    height: fit-content;
    display: flex;
    justify-content: space-around;
}
.testimonials-Box-title h1{
    color: white;
}
.header{
    color: white;
}
.slider-component{
    max-height: 380px;
    margin:30px auto 0;
    margin-top: 40px;
    max-width: 1200px;
}
.single-testimony {
    background-color: white;
    margin: 0 15px 30px;
    padding: 30px;
    text-align: center;
    height: max-content;
    border-radius: 10px;
    /* max-width: 540px; */
    position: relative;
}

.single-testimony:after {
    position: absolute;
    top: auto;
    left: 39px;
    content: "";
    width: 0;
    height: 0;
    z-index: 1;
    border-style: solid;
    border-width: 15px 15px 0 15px;
    border-color: #fff transparent transparent transparent;
    bottom: -15px;
    left: 50%;
    transform: translatex(-50%);
}


.testimonials-Box:after{
    position: absolute;
    width:150px;
    height:150px;
    background: url('../../../assets/quotes.png') no-repeat center;
    content:"";
     background-size: contain;
     top:30px;
     right:30px;
     opacity: 0.3;
}

h1.testimony-head{
    color:#130F26;
    font-feature-settings: "salt" on "liga" off;
    margin-bottom:10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 10px;
}

.testimony-body{
    font-family: 'Montserrat';
    line-height: 24px;
    color: #282D6C;
}

.testimony-user-info{
    display: flex;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    width: fit-content;
    margin: auto;
}

p.comment-name{
    font-weight: 600;
    color: #FFFFFF;
    line-height: 28px;
    font-feature-settings: 'salt' on, 'liga' off;
}

.comment-status{
    font-weight: 500;
    color: #FFFFFF;
    font-family: 'Montserrat';
    line-height: 24px;
    font-feature-settings: 'salt' on, 'liga' off;
}

.comment-icon{
    height: 60px;
    width: 60px;
    margin: 0 1vh;
}

.testimonials-Box .slick-dots{
    bottom: -75px;
}
.slider-component .slick-next , .slider-component .slick-prev{
    display: none !important
   }

@media only screen and (max-width: 600px) { 
/*Mobile screen displays*/
.iconsDiv img{
    height: 50px;
    width: 50px;
    margin-top: 0;
}


.halfCircle{
    background-size: 100%;
    padding: 53px 0 33px 13px;
}

.iconsDiv2{
    width: 70%;
}
.iconsDiv2 img{
    height:60px;
    width:60px;
    gap: 0px;
    padding: 0px;
}

.testimonials-Box{
    margin: 0px;
    width: 100%;
    max-height: 600px;
}
.slider-component{
    margin: 10px 10px;
    width: initial;
    max-height: 460px;
}


.testimony-user-info{
    margin: 30px auto;
}
.titleBox{
    margin: auto;
    text-align: center;
    max-width: unset;
    margin-bottom: 25px;
}
}





@media only screen and (max-width: 961px) and (min-width:600px){ 
/*Medium screen displays*/
.titleBox{
    margin: auto;
    text-align: center;
    max-width: unset;
    
}
.iconsDiv{
 margin: auto;
}
.halfCircle{
    margin: auto;
    width: 90%;
    background-size: 100%;
    padding: 0px;
}
.testimonials-Box{
margin-top: 1vh;
margin-bottom: 3vh;
}

.single-testimony{
    margin: 2vh;
    height: fit-content;
    padding:10px;
    width: fit-content;
    max-height: 326px;
}
.testimony-user-info{
    margin: 30px auto;
}
}





@media only screen and (min-width:961px)and (max-width:1337px){
/*Large screen displays*/
.halfCircle{
    background-size: contain;
}

.testimony-user-info{
    margin: 30px auto;
}
}
@media only screen and (min-width:900px) and (max-width:1000px) {
 .iconsDiv img{
    height: 55px;
    width: 55px;
    margin-top: 30px;
 }
 .iconsDiv2 img{
    height: 80px;
    width: 80px;
    margin-top: 10px;
 }

}

@media (max-width:960px){
    .testimonials-Box-title{
        margin-bottom: 30px;
    }
}

@media (max-width:600px){
    .FithView .slick-dots {
        bottom: -22px;
    }

    .testimonials-Box{
        padding: 60px 15px;
    }

    .testimonials-Box:after{
        width: 89px;
        height: 70px;
    }

    .single-testimony{
        padding: 30px 20px;
        margin: 0;
    }

    .testimonials-Box .slick-dots {
        bottom: -25px;
    }
}
