div.categories-container{
    position: sticky;
    top: 5px;
    background-color: #fff;
    width: 90%;
    margin: auto;
    border-radius: 4px;
    padding: 15px 0px;
    display: block;
}
div.categories-container >div{
    padding: 1vh;
    
}
div.a-category{
    display: flex;
    gap: 20px;
    margin: 1vh 5px;
    padding: 10px;
    cursor: pointer;
    transition: 0.4s;
    border-radius: 4px;
}
div.a-category:hover{
    background-color: #eeeeee;
}
div.a-category p{
    font-size: 16px;
    color:#64748B;
    margin: 0px;
    font-weight: 500;
}
/* div.btn-div{
    display: flex;
    gap: 5px; 
} */
div.btn-div button{
    display: flex;
    justify-content: center;
    border-radius: 5px;
    min-width: unset !important;
}

@media only screen and (max-width: 600px) { 
/*Mobile screen displays*/
div.categories-container{
    display: none;
}
}





@media only screen and (max-width: 961px) and (min-width:600px){ 
/*Medium screen displays*/
div.categories-container{
    display: none;
}
}




@media only screen and (min-width:961px)and (max-width:1320px){
/*Large screen displays*/
.cusIco{
    display: none !important;
}
}

@media only screen and (min-width:961px)and (max-width:1200px){
    /*Large screen displays*/
    div.categories-container{
        display: none;
    }
}
