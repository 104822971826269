table{
    border-collapse: collapse;
}
td,th{
    padding: 5vh 90px 3vh 10px;
}
tr:hover {
    background-color: #efedff;
    border-radius: 200px;
    cursor: pointer;
    
}
tr:hover .amt-div{
    transition: 0.5s;
    background-color: #ff6a55;
    color: white;
}
.history-container-table{
    width: 100%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
}
table p{
    margin: 0px;
}
.table-wrapper{
    width: 100%;
    overflow-x: auto;
}
.the-table{
    margin: 0px;
    padding: 0px;
    width: 100%;
}
table th{
    text-align: left;
    
}
td.user{
    display: flex;
    gap: 10px;
}
td.user p.name{
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 24px;
color: #1A1D1F;
}
td.user p.atUser{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 13px;
color: #9A9FA5;
}

td.email{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #6F767E;
    vertical-align: top;
}

td.amount >div{
    border-radius: 5px;
    background-color: #EFEFEF;
    text-align: center;
    margin-top: -20px;
}

td.amount p{
    font-family: 'Inter';
    font-weight: 700;
    font-size: 15px;
    color: #1A1D1F;
}
td.item p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    color: #1A1D1F;
    margin-top: -20px;
}


@media only screen and (max-width: 600px) { 
/*Mobile screen displays*/
.history-container{
    border: 1px solid #DEDAFB;
    width: 100%;
    box-shadow:0px 2.89381px 5.85841px 2px #eeeeee;
    background-color: white;
    margin-bottom:26px;
    padding: 20px;
    border-radius: 8px;
}
.history-container > h1{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 32px;
margin: 0px;
}

.history-container-single{
    display: flex;
    margin-top: 30px;
    gap: 20px;
}
.history-container-imace-holder{
    background-color: greenyellow;
    height: 90px;
    width: 90px;
    border-radius: 10px;
    margin: 0px;
}
.single-header{
    display: flex;
    justify-content: space-between;
}
.single-content-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0px;
    width: 60%;
}
.single-header p{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;
color: #1A1D1F;
margin: 0px;
}
.single-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.single-price-box{
    padding: 2px;
    margin: 0px;
    background-color:#eeeeee;
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 15px;
color: #83BF6E;
border-radius: 4px;
margin-right: 20px;
}
.single-footer p{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 15px;
color: #9A9FA5;
margin: 0px;
}
.the-history-items{
    width: 100%;
}
div.loadMore{
    width: fit-content;
    margin: 15px auto;
}
}



@media only screen and (max-width: 961px) and (min-width:600px){ 
/*Medium screen displays*/

table th.themial, .email{
    display: none;
}
.history-container-table{
    margin: 10px;
}
.table-wrapper{
    margin: 0px auto;
}
.history-container-table > h1{
    margin: 20px 0px;
    text-align: center;
}


}




@media only screen and (min-width:961px)and (max-width:1337px){
/*Large screen displays*/

table th.themial{
    display: none;
}
.history-container-table{
    margin: 10px;
}
.table-wrapper{
    margin: 0px auto;
}
.history-container-table > h1{
    margin: 20px 0px;
    text-align: center;
}

}



@media only screen and (max-width: 700px) and (min-width:600px){ 
    /*Medium screen displays*/
    
    table th.themial, .email{
        display: none;
    }
    .history-container-table > h1{
        text-align: center;
        margin: 20px 0px;
    }
    
    }


    @media (max-width:900px){
        td,th{
            padding: 40px 20px;
        }
    }