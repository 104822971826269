.all-followers .profile {
  position: relative
}

.follow-actions {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  border-radius: 50%;
  padding: 7px 10px 17px;
  color: #937c7c;
  font-size: 21px;
}

.follow-search {
  display: block;
  background: #efefef;
  padding: 10px 18px 14px 20px;
  border-radius: 15px;
  position: relative;
  display: flex;
  max-width: 400px;
  margin: 15px auto;
}

.follow-search i {
  margin-right: 10px
}

.follow-search input[type="serach"] {
  border: 0;
  background: none;
  outline: none;
  width: 100%;
  border-bottom: 1px solid #d1d1d1;
}

.follow-wrap {
  padding: 50px 0
}

.follow-wrap .container>h4 {
  color: #73779F;
  margin: 10px 0;
}

.follow-wrap h3 {
  font-size: 30px;
}

.hero-follower {
  padding: 80px 15px 30px;
}

.follow-wrap .container {
  max-width: 700px
}

.follow-action-back ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.follow-action-back ul li {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
  color: #000
}

.follow-action-back ul li:hover {
  background: #eee;
}

.follow-action-back ul li span {
  display: block
}


.follow-action-back ul li h6 {
  margin: 0;
  font-size: 14px
}

.follow-action-back ul li h6 span {
  display: block;
  color: #73779F;
  font-size: 11px
}


.follow-action-back {
  padding: 20px 10px;
  background: #fff;
  box-shadow: -2px -2px 10px rgb(0 0 0 / 21%);
  position: absolute;
  right: 0;
  top: 66px;
  display: block;
  z-index: 2;
display: none;
}


.follow-action-back.active{
  display: block;
}

@media (max-width:767px) {
  .hero-follower {
    padding: 40px 15px 20px;
  }


}