p {
    margin: 0px;
    padding: 0px;
}

.header-wrapper {
    position: relative;
    z-index: 100;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    background-color: white;
}

.HeaderBox {
    /*The header Desktop Container*/
    background-color: white;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: 75px;
    /* position: fixed; */
    width: 100%;
    /* z-index: 10;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09); */
    max-width: 1200px;
}

.Logo-box {
    /*website logo*/
    margin: auto;
    display: flex;
    align-content: center;
    justify-content: space-around;
}

.Logo-box img.ipaMenuIcon {
    /*Hide sub menu on large screen*/
    display: none;
}

.Logo-box img {
    height: 90%;
}

.Accordion-Open {
    display: none;
}

.Nav-links-left {
    margin: 0px;
    padding: 0px;
}

.Nav-links-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    padding: 0px 10px;
}

.Nav-links-right div.auth {
    display: flex;
    justify-content: space-around;
}

.Nav-links-right .MuiButtonBase-root {
    text-transform: none;
}

.Nav-links-right .MuiButton-text {
    margin: 0px 10px;
}

.Nav-links-right img {
    color: #6F7FA7;
    padding-right: 30px;
    height: 10%;
    padding-top: 10px;
}

.Single-header-link {
    text-decoration: none;
    text-align: center;
    margin: 0vh 3vh;
    color: #73779F;
    font-size: 14px;
    width: fit-content;
    transition: 0.3s all;
}

.Single-header-link:hover {
    color: #5A48FA
}

.Single-header-active-link {
    text-decoration: none;
    text-align: center;
    margin: 0vh 3vh;
    color: #5A48FA;
    font-size: 14px;
    border-bottom: 2px solid #5A48FA;
    padding-bottom: 5px;
}

.RouteLink {
    display: flex;
    text-decoration: none;
    color: #6F7FA7;
    margin: 4vh 0vh;
    font-weight: 500;
    font-size: 16;
}

.smallerLinks {
    display: block;
    text-decoration: none;
    color: #6F7FA7;
    margin: 2vh 0vh 4vh 2vh;
    font-size: 14;
    font-weight: 400;
}

.smallerAccordion {
    background-color: inherit;
    margin: 0vh;
    margin-top: 1vh;
    padding: 0px;
    height: fit-content;
}

.side-bar-icon-close {
    float: right;
    margin: 2vh 3vh;
    color: #35363a;
    cursor: pointer;
    border-radius: 100px;
    font-size: x-large;
}

.side-bar-icon-close:hover {
    background-color: #bababa;
    transition: 0.5s;
}

.mobile-muiBtn-contained {
    text-transform: initial !important;
    margin-top: 10px !important;
    padding: 10px !important;
}

.mobile-muiBtn-contained .genEsc {
    background-color: #04063d;
}

.desktop-muiBtn-contained {
    padding: 3vh;
    text-transform: none;
    width: 95%;
    background-color: #5A48FA;
}

.menu-icon-close-box {
    display: none;
}

.signed-in-user-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px;
    align-items: center;
}

.signed-in-user-info1 {
    display: flex;
    justify-content: space-between;
    width: fit-content;
    padding: 0px;
}

.signed-in-user-avatar {
    margin: 0px;
    padding: 0px;
    height: 50px;
    width: 50px;
    margin-right: 1vh;
}

.signed-in-user-info2 {
    margin-top: 0.5vh;
}

.user-infor-name {
    margin: 0px;
    font-size: 15px;
    font-weight: 600;
    color: #1A1D1F;
}

.user-infor-position {
    margin: 0px;
    font-size: 12px;
    font-weight: 500;
    color: #6F767E;
}

.user-info-rating {
    margin-right: 2vh;
}

.user-info-dropdown-box {
    background-color: #F4F4F4 !important;
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.02), inset 0px -1px 1px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
}
.marketplace-category-list{
    margin: 10px 0px;
    padding: 0px;
}

body .Nav-links-right button {
    color: #5A48FA;
    border: 1px solid rgba(91, 73, 254, 0.5);
    border-radius: 4px;
}

body .Nav-links-right button:not(.MuiButton-text):hover {
    background: #5A48FA;
    color: #fff
}

body .Nav-links-right .MuiButton-text {
    border: none;
    color: #73779F
}

body .Nav-links-right .MuiButton-text:hover {
    background: none;
    color: #5A48FA;
}

body .Nav-links-right button {
    color: #5A48FA;
    border: 1px solid rgba(91, 73, 254, 0.5);
    border-radius: 4px;
}

body .Nav-links-right button:not(.MuiButton-text):hover {
    background: #5A48FA;
    color: #fff
}

body .Nav-links-right .MuiButton-text {
    border: none;
    color: #73779F
}

body .Nav-links-right .MuiButton-text:hover {
    background: none;
    color: #5A48FA;
}

@media only screen and (max-width: 600px) {
    /*Mobile displays*/
    /* *{
    background-color: yellow;
    } */

    .Accordion-Open {
        display: block;
    }

    .Logo-box {
        display: none;
    }

    .Nav-links-left {
        display: none;
    }

    #mobileNav {
        padding: 0vh 3vh 0vh 3vh;
        position: absolute;
        height: 100vh;
        width: 100%;
        top: 0;
        background-color: white;
        animation-name: runn;
        animation-duration: .4s;
        transition: all 1s;
        overflow: scroll;
        z-index: 20;
    }

    .menu-icon-close-box {
        display: flex;
        justify-content: flex-end;
    }

    .menu-icon-close {
        font-size: 4vh;
        margin: 5%;
        color: #6F7FA7;
    }

    .user-btn1 {
        text-transform: initial;
        margin-top: 10px;
        padding: 10px;
        width: 100%;
        display: none;
    }

    .marketplace-category-list {
        margin: 10px 0px;
        padding: 0px;
    }

    .btnIcon {
        margin: 0px 10px;
    }

    .marketplace-dropDown {
        height: fit-content;
        margin: 0px 0px 0px 27%;
        display: flex;
    }

    .marketplace-dropDown p {
        font-size: 15px;
        font-weight: 600;
        color: #1A1D1F;
        margin: 0px;
    }

    .Mobile-HeaderBox {
        /*The header Mobile container*/
        height: 8.5vh;
        display: flex;
        justify-content: space-between;
        position: fixed;
        width: 100%;
        background-color: white;

        box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
        top: 0
    }

    .Mobile-Header-left {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 40%;
    }

    .Mobile-Header-left img {
        height: 3vh;
    }

    .Mobile-Header-right {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 23%;
    }

    .Mobile-Header-right img {
        height: 4vh;
    }

    .not-signed-in {
        display: flex;
        justify-content: center;
    }
}


@media only screen and (max-width: 961px) and (min-width:600px) {

    /* *{
background-color: green;
} */
    .Logo-box {
        margin: auto 10px;
    }

    .Logo-box img.ipaMenuIcon {
        display: initial;
        margin: auto 10px;
        cursor: pointer;
    }

    .Nav-links-left {
        display: none;
    }

    .Nav-links-right {
        margin: 10px;
    }

    .Nav-links-right img {
        display: none;
    }

    .RouteLink {
        margin: 4vh 4vh;
    }

    .smallerAccordion {
        margin: 1vh 4vh;
    }

    .content-box {
        padding: 10px;
    }
.marketplace-dropDown{
    height: fit-content;
    margin: 0px 0px 0px 27%;
    display: flex;
}
.marketplace-dropDown p{
    font-size: 15px;
    font-weight: 600;
    color: #04063D;
    margin: 0px;
    margin-left: 10px;
}
}

@media only screen and (min-width:961px)and (max-width:1202px) {

    .Single-header-active-link {
        display: none;
    }

    .Single-header-link {
        display: none;
    }

    .Nav-links-right img {
        display: none;
    }

    .Logo-box img.ipaMenuIcon {
        display: initial;
        margin: auto 10px;
        cursor: pointer;
    }

    .Logo-box {
        margin: auto 10px;
    }

    .Nav-links-left {
        display: none;
    }

    .Nav-links-right {
        margin: 10px;
    }
    .marketplace-dropDown{
        height: fit-content;
        margin: 0px 0px 0px 27%;
        display: flex;
    }
    .marketplace-dropDown>p{
        font-size: 15px;
        font-weight: 600;
        color: #04063D;
        margin: 0px;
        margin-left: 10px;
    }
}
