div.main-contract-container{
    background-color: white;
    margin: 10px;
    padding:20px;
    border-radius: 5px;
    
}
div.single-contract-type{
    max-width: 650px;
}

div.single-contract-type input {
    height: 45px !important;
    width: 312px !important;
    margin-bottom: 17px;
}
div.single-contract-type  select {
    width: 120px;
    margin-bottom: 17px;
}
div.single-contract-type div.secondForm{
border: 0.975853px solid #EAEAF0;
border-radius: 7.80683px;
max-width: 600px;
padding: 10px;
}


div.small-input-div{
display: block;
}
div.small-input-div input{
    max-width: 180px !important;
    max-height: 30px !important;
}

div.small-input-div select{
    max-width: 180px !important;
    width: 100% !important;
    /* min-width: unset !important; */
    max-height: 30px !important;
    /* padding:10px 0px !important; */
}
input#refNum{
    max-width: unset !important;
    width: 100% !important;
}
textarea#descript{

    max-height: 73px !important;
}
/* div.single-contract-type{
    display: contents;
} */
div.milestone-container {
    max-width:500px;
    padding: 10px;
}
div.milestone-container *{
    width: 100%;
}
div.main-contract-container input[type="number"]{
    background: transparent;
    border: 0.975853px solid #EAEAF0;
    border-radius: 7.80683px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13.6619px;
    line-height: 100%;
    color: #B4B6CC;
    padding: 16px 20px;
    display: block;
    margin-bottom: 10px;
    width: 100%;
    outline: none;
    font-family:"montserrat" , sans-serif;
}

div.main-contract-container input[type="number"]:focus
{
    border-color: #5A48FA
}
div.add-milestone{
    display: flex;
    gap: 5px;
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
}
div.add-milestone p , div.add-milestone span{
    margin: 0px;
}
div.add-milestone span{
    padding: 3px;
    color: red !important;
}
div.add-milestone:hover{
    cursor: pointer;
    color: #5a48fa !important;
    background-color: #eee;
    transition: 0.6s;

}

@media only screen and (max-width: 600px) { 
    /*Mobile screen displays*/
div.main-contract-container{
    margin: -20px;
    padding-top: -40px;
}

}


@media only screen and (max-width: 961px) and (min-width:600px){ 
    /*Medium screen displays*/



}


@media only screen and (min-width:961px)and (max-width:1337px){
    /*Large screen displays*/


}
