div.marketplace-slider-container{
    margin-bottom: 70px;
}
.add-conatiner{
    background-image: url(../../../assets/circularBg.svg);
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: 100%;
    background-color:#623cea;
    min-height: 360px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 32px;
}
div.add-conatiner>div{
    padding: 4vh 0vh 0vh 5vh;
    width: 35%;
}
div.add-conatiner h1{
    color: white;
    font-weight: 600;
    font-family: 'Plus Jakarta Sans';
    font-size: 32px;
    margin-bottom: 20px;
}
div.add-conatiner p{
    color: white;
    font: 'Plus Jakarta Sans';
    line-height: 150%;
    text-align: left;
}
div.popular-escrow{
    width: 100%;
    background-color:#04063D;
    height: 50px;
    margin:0px;
    padding: 12px 10px;
    margin-bottom: 20px;
}
div.popular-escrow p{
    color: white;
    font-weight: 600;
}
div.top-escrow{
    width: 100%;
    background-color: white;
    margin: 30px 0px;
    padding: 16px 15px
}
div.top-escrow p{
    color:#04063D;
    font-weight: 600;
}
div.Item-container{
    width: fit-content;
    margin: 0px auto;
}


@media only screen and (max-width: 600px) { 
/*Mobile screen displays*/
.escrow-marketplcae-container{
    padding: 0px 2vh;
    background-color:inherit;
}
div.add-conatiner{
    height: 35vh;
    margin-top: 0px;
    padding: 20px;
    background-image: url(../../../assets/circullar-telBg.svg);
    background-size:cover;
}
div.add-conatiner>div{
    padding: 10px;
    width: 100%;
}
div.add-conatiner h1{
    font-size: 16px;
    font-weight: 600;
}
div.popular-escrow{
    display: flex;
    justify-content: space-between;
    background-color: inherit !important;
}
div.popular-escrow p{
    color: #04063D;
    font-weight: 600;
}
div.popular-escrow a{
    color:#3563E9;
    font-weight:600;
    text-decoration: none;
    font-family: 'Plus Jakarta Sans';
}
}






@media only screen and (max-width: 961px) and (min-width:600px){ 
/*Medium screen displays*/
div.add-conatiner>div{
    width: 50%;
}
div.add-conatiner{
    background-size: cover;
}
}






@media only screen and (min-width:961px)and (max-width:1337px){
/*Large screen displays*/
div.add-conatiner>div{
    width: 50%;
    }
    div.add-conatiner{
        background-size: cover;
    }


}

