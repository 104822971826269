.firstView{
    max-width: 1200px;
    position: relative;
    margin: auto;
    min-height:700px;
    padding:150px 15px 100px;
}

.gradient-text{
    width: 100%;
    height: fit-content;
    line-height: 120%;
    background:linear-gradient(rgba(90, 73, 250, 1),rgba(4, 6, 61, 1));
    -webkit-text-fill-color: transparent;
    background-clip: text;
    display: inline-block;
}

.never{
-webkit-text-fill-color: white;
background:#4EE9FF;
color:white;
padding:1.5vh;
font-weight: bold;
line-height: 120%;
display:inline-block;
z-index:-1;
}

.form-div{
    margin-left: auto;
    margin-right: 0;
    padding: 20px;
    max-width: 410px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(90, 72, 250, 0.1);
    border-radius: 4px;
}

.mui-form-control{
    padding: 0px;
    width: 100%;
    margin-bottom: 19px !important;
    background-color: #ffffff;
}
.form-div span.error{
    color: red;
    font-size: 13px;
    margin: 10px 0 5px;
    display: block;
}

.mui-form-select{
    color:#04063D;
    font-size: 18px;
    font-weight: 500;
}

.form-inputField{
    font-size: 500;
    margin: 0px !important;
    padding: -10px !important;
    border: none !important;
}

input.form-inputField{
    background-color: red !important;
}
.homeInput{
    color: #04063D !important;
    font-weight: 500 !important;
    border: 1px solid #9b9cb1 !important;
    margin: 0px !important;
    height: 58px;
    border-radius: 5px !important;
    font-size: 14px !important;
}
.form-div button{
    width: 100%;
    font-family: 'SF Pro Display';
    font-weight: 600;
    border-radius: 4px !important;
    margin-top: -5px !important;
}
/*The blurry colorful circullar backgrounds*/
.blur-5{
    top: 20%;
    left: 40px;
    background:#4DE6FC;
    
}
.blur-6{
    top: 40%;
    left: 20%;
    background:#AA80F9;
    
}

span.closebtn {
    position: absolute;
    top: 13px;
    right: 18px;
    cursor: pointer;
}


.contractformWrap .css-1gkse3u {
    border: none
}

@media only screen and (max-width: 600px) { 
/*Mobile screen displays*/
.form-div{
    margin: auto;
    background-color: unset;
    box-shadow: none;
    width: 100%;
    max-width: 100%;
    padding: 0px;
}

.gradient-text{
width:100%;
margin-left:0px;
text-align:left;
/* padding:0px 20px; */
border:none;
margin-right:0px;
font-size:40px;
line-height:125%;
font-weight:600;
padding-bottom:0px;
height:20%;
}
.never{
    font-size:40px;
    line-height:120%;
    font-weight:600;
}
.mui-form-control{
    width: 100%;
    margin: 0px;
    padding: 0px;
    margin-bottom: 10px !important;

}
.mui-form-control span{
    margin: 0px;
    height: fit-content;
}
.mui-form-select{
    background-color:#FFFFFF !important;
    border: 1px solid #ECECEC !important;
}
.blur-4{
    width: 40%;
    height: 20%;
    filter: blur(70px);
    top: 140px;
    left: 10%;
    background:#5A48FA;
    margin: 0px;
   
}
.blur-5{
    width: 40%;
    height: 20%;
    filter: blur(70px);
    top: 80px;
    left: 30%;
    background:#4DE6FC;
    margin: 0px;

}
.blur-6{
    width: 40%;
    height: 20%;
    filter: blur(70px);
    top: 145px;
    left: 40%;
    background:#AA80F9;
    margin: 0px;
}
.homeInput{
    margin-bottom: 10px !important;
}
.css-1gkse3u{
    min-width: 95%;
}
}






@media only screen and (max-width: 961px) and (min-width:600px){ 
/*Medium screen displays*/
.form-div{
    max-width: 410px;
    height: fit-content;
}
.gradient-text{
    font-size:44px;
    font-weight:600;
    height:20%;
    width:fit-content;
}
.never{
    font-size:3.5rem;
    line-height:120%;
    font-weight:600;
}
.mui-form-control{
    margin: 0px;
}
.firstView{
    height: 100%;
}
}





@media only screen and (min-width:961px)and (max-width:1337px){
/*Large screen displays*/
.gradient-text{
    font-weight:600;
    height:20%;
    padding:0px;
    width:fit-content;
}
.never{
    font-size:3.5rem;
    line-height:120%;
    font-weight:600;
}
.form-div{
    max-width: 410px;
    height: fit-content;
}
.mui-form-control{
    margin: 0px;
    padding: 0px;
}
  .firstView{
        margin: unset;
        height: 100%;
    }

}


@media (max-width:900px){
   /* .firstView {
        max-width: 800px;
        padding: 80px 15px ;
    }
    .firstView .form-div {
        margin: 70px auto 0;
    } */
}


@media (max-width:767px){
    .firstView .form-div {
        margin: 30px auto 0;
    }

    .firstView {
        min-height: 650px;
        padding: 80px 15px 50px;
    }


}

@media (max-width:600px){
    
body .form-div button[type="submit"] {
    margin-top: 20px !important;
}
}
