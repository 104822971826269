.FourthView{
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
     padding: 100px 15px;
    height: fit-content;
    background-image: url('../../../assets/backgroundPath3.svg');
    background-repeat: no-repeat;
}
.whoWeAre-container{
    display: flex;
    justify-content: space-between;
    padding: 0 10vh;
}
.chip-box{
    display: flex;
    flex-direction: column;
}
.details-box{
    width: 50%;
}
.details-text{
    width: 100%;
    color: #505785;
}
.centered-text{
    margin: 5px 0px;
}
.video-container{
    width: fit-content;
    margin: auto;
    margin-top: 8vh;
}
.actualVid{
    width: 1096px;
    height: 550px;
    max-width: 1096px;
    min-height: 550px;
}
p.learnMore{
    margin-top: 20px;
    color: #8B8BFD;
    cursor:pointer;
}
.arrowleft{
    color:#5A48FB;
    position: relative;
    top: 1vh;
}
.leftAlign{
    margin: 0px;
}
.blur-12{
    background-color: #4DE6FC;
    width: 300px;
    left: 40%;
    top: 0%;
}

@media only screen and (max-width: 600px) { 
/*Mobile screen displays*/
.whoWeAre-container{
    display: block;
    padding: 0px;
    margin: 0px;
}
.chip-box{
    width: 100%;
    margin: auto;
    align-items: center;
}
.centered-text{
    text-align: center;
    width: 272px;
    margin: auto;
}

.actualVid{
    width: 100%;
    height: 100%;
}
.video-container{
    width: 100%;
    margin: 0px;
    margin-top: 5vh;
}
.leftAlign{
    margin: auto;
}
.blur-12{
    display: none;
}
}






@media only screen and (max-width: 961px) and (min-width:600px){ 
/*Medium screen displays*/
.whoWeAre-container{
    padding: 0 2vh;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

}
.chip-box{
    width: 100%;
    margin: 20px;
    
}
.details-box{
    width: 100%;
}

.actualVid{
    width: 100%;
    height: 100%;
    margin: 0px;
}
.video-container{
    width: 100%;
    height: fit-content;
display: flex;
justify-content: center;
margin-top: 7vh;
}
.FourthView{
    padding: 0px !important;
}
}







@media only screen and (min-width:961px)and (max-width:1337px){
/*Large screen displays*/
.whoWeAre-container{
    padding: 0 2vh;
}
.chip-box{
    width: 50%;
}

.actualVid{
    width: 100%;
    height: 100%;
}
.video-container{
    width: 100%;
    height: fit-content;
display: flex;
justify-content: center;
margin-top: 7vh;
}
}


@media (max-width:767px){
    .FourthView{
        padding: 0px;
    }
}
