.admin-user-stats{
    max-width: 237px;
    background-color: white;
    font-family: 'SF Pro Display';
    position: sticky;
    top: 20px;
}
.admin-user-stats p{
    margin: 0px;
    font-weight: 500;
    font-family: 15px;
}
.admin-user-stats h5{
    color: #0F172A;
    font-size: 16px;
    font-weight: 500;
} 

/* Upper Section */
.admin-user-stats .admin-user-about{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 25px;
    border-bottom: 1.5px solid #C4C2D3;
    text-transform: uppercase;
}
.admin-user-stats .admin-user-about p{
    color: #94A3B8 ;
    font-weight: 500;
    font-family: 15px;
}

/*---------------------------*/

/* Lower section */
.admin-user-stats .admin-user-options{
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #64748B;
    padding: 15px;
}
.admin-user-options p{
    color: inherit;
    font-size: 15px;
}
div.admin-user-options > div{
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-radius: 5px;
}
div.admin-options-single-left{
    display: flex;
    gap: 15px;
}
div.admin-options-single-ico{
    margin: 5px 0px;
}
div.admin-user-options > div:hover{
    color: white !important;
    background-color: #5a48fb;
    cursor: pointer;
    transition: 0.4s;
}
/* .admin-ico{
    height: 20px;
    width: 20px;
} */
/*---------------------------*/






