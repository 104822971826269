
.Footer{
    width: 100%;
    overflow: hidden;
    background-image: url(../../../assets/backgroundPath5.svg);
    background-repeat: no-repeat;
    position: relative;
    padding: 90px 15px;
}
.title{
    color: #282D6C;
    text-align: center;
    margin: auto;
    max-width: 769px;
}

.emialInput{
    display: flex;
    justify-content: center;
    gap:10px;
    margin-top: 48px;
}
.emialInput input{
    background-color: white !important;
    color: #282D6C !important;
}
.emialInput button{
    height: 100% !important;
    margin: 2px 0px !important;
}
.emialInput input{
    border: 1px solid #ADADAD;
    border-radius: 8px;
    width: 100%;
    max-width: 461px;
    padding: 10px;
}

.emialInput button{
    height: 48px;
    background-color: #5A48fA;
    border-radius: 7.5px;
    padding: 10px 40px;
    color: white;
    border: 2px solid #5A48fA;
    max-width: 200px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
}
.emialInput button:hover{
    color: #5A48fA;
    background-color: white;
    transition: 0.3s;
}

.footer-links-container{
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid rgba(173, 173, 173, .5);
    margin-right: auto;
    margin-left: auto;
    margin-top: 10vh;
}
.footerLink{
    display: block;
    text-decoration: none;
    color: #282D6C;
    width: fit-content;
    font-size: 15px;
    padding-bottom: 20px;
}

.footerLink:hover{
    text-decoration: underline;
}

.footer-socialMediaLinks{
    display: flex;
    gap: 24px;
}

.footer-socialMediaLinks a{
    color: #5A48FA;
    transition: 0.3s all;
}

.footer-socialMediaLinks a:hover{
    transform:  scale(1.2);
}
.blurry-background{
width: 300px;
height: 300px;
opacity: 0.5;
filter: blur(100px);
position:absolute;
z-index:-1;
bottom: 0;
border-radius:1000px;

}

.blur-1{
    width: 100%;
    top: 26%;
    left: 5%;
    background:#5A48FA;
}
.blur-2{
    width: 100%;
    margin: 0px;
    bottom: 0px;
    background:#4DE6FC;
}
.blur-3{
    width: 100%;
    background:#AA80F9;
    right: 0px;
}
.linkTitle{
    margin: 0px;
    margin-bottom: 40px;
}
.cpyBox {
    display: flex;
    justify-content: space-between;
    max-width: 1115px;
    margin: 30px auto 0;
}


.footer-container{
    max-width: 1200px;
    margin: 0 auto;
}

.footer-links-container h2 {
    font-family: 'SF-Pro-Display';
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    margin-bottom: 40px
}

.footer-links-container>div>div {
    flex-grow: 0;
    max-width: 100%;
    width: calc(20% - 10px);
    flex-basis: auto;
    margin: 0 5px 40px;
}

.footer-links-container {
    max-width: 1115px;
   
}


.emialInput input[type="text"]{
    margin-bottom: 0;
}


@media (max-width:1024px) {

    .footer-links-container, .cpyBox {
        max-width: 800px;
    }
    .footer-links-container>div>div {
        width: calc(33.33% - 10px);
    }

    .footer-links-container h2 {
        margin-bottom: 30px;
    }

    .Footer{
        padding: 70px 15px;
    }
}

@media (max-width:767px) {
    .footer-links-container>div>div {
        width: calc(50% - 10px);
    }

    .Footer{
        padding: 50px 15px;
    }
}


@media only screen and (max-width: 600px) { /*Mobile displays*/
    .Footer{
        height: fit-content;
        background: none;
    }
    .emialInput{
        flex-direction: column;
        justify-content: space-between;
        margin: 10px;
        margin-bottom: 72px;
    }
    .emialInput input{
        margin: auto;
        width: 80%;
        height: 48px;
    }
    .emialInput button{
        margin: auto;
    }
    .footer-links-container{
        flex-direction: column;
        margin: 5vh auto 0;
        border-bottom: none;
    }
    .blurry-background{
        display: block;
        height: 50vh;
        width: 50%;
        filter: blur(100px);
    }
    .blur-1{
        left: 0px;
        background:#5A48FA;
        width: 100%;
        filter: blur(160px);
    }
    .blur-2{
        top:30%;
        width: 100%;
        filter: blur(160px);
        background:#4DE6FC;
    }
    .blur-3{
        background:#AA80F9;
        width: 100%;
        filter: blur(160px);
    }
    .title{
        width: 90%;
        font-size: 24px !important;
    }
    .emialInput button{
        margin: auto !important;
    }

    .emialInput {
        gap: 25px;
        margin-top: 29px;
    }

}

@media only screen and (max-width: 961px) and (min-width:600px){  

    .Footer{
        width: 100%;
        height: fit-content;
        margin: auto;
    }
    .emialInput{
        margin: 2vh;
    }
    .blurry-background{
        opacity: 0.5;
        filter: blur(100px);
        margin: 0px;
    }
}

@media (max-width:478px){
    .footer-links-container>div>div {
        width: 100%;
        margin: 0 0 30px;
    }
}
