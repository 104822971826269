.marketpalceSearch-wrap{
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    margin-top: -20px;
    position: relative;

}
div.Market-place-search{
    display: flex;
    gap: 3px;
}
div .Search-flex{
    display: flex;
    gap: 10px;
    position: relative;

}
.Market-place-search p{
font-style: normal;
font-weight: 600;
font-size: 18.6115px;
letter-spacing: -0.02em;
color: #04063D;
margin: 0px 10px 0px 0px;
}
.marketpalceSearch-wrap input,.marketpalceSearch-wrap select{
    /* height: 20px !important;*/
    height: 30px !important; 
    padding: 5px 10px !important;
    background-color: white !important;
    border-radius: 13px !important;
    padding-right: 35px !important;
    width: max-content;
}
.marketpalceSearch-wrap input{
    max-width: 190px !important;
}

div.markets{
    display: flex;
    gap: 15px;
}
.markets p{
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 12px;
margin: 0px;
color: #6E7D93 !important;
display: flex;
justify-content: space-around;
align-items: center;
}
.marketpalceSearch-wrap img{
    margin: 2px;
}
.marketpalceSearch-wrap img.searchIco{
    position: absolute;
    height: 2rem;
    width: 2rem;
    right: 0px;
    padding: 4px;
    top: -3px;
}




@media only screen and (max-width: 600px) { 
/*Mobile screen displays*/
div.marketpalceSearch-wrap{
    padding: 0px auto;
    justify-content: center;
}
div.Search-flex{
    padding: 0px 10px;
}
div.Search-flex2{
    justify-content: space-between;
}
.marketpalceSearch-wrap{
    margin: 0px;
    margin-top: 50px;
    padding: 0px;
}
div.markets,.Market-place-search >p{
    display: none;
}
.marketpalceSearch-wrap input{
    max-width: unset !important;
    height: 50px !important;
    width: 100% !important;
    margin: 0px !important;
}
.marketpalceSearch-wrap select{
    max-width: 90px !important;
    height: 50px !important;
}
.marketpalceSearch-wrap img.searchIco{
    top: 8px;
    padding: 0px;
    max-width: unset !important;
    right: 15px;
}
}






@media only screen and (max-width: 961px) and (min-width:600px){ 
/*Medium screen displays*/
.Market-place-search p{
display: none;
    }
    div.markets{
        margin-top: -10px;
    }

}






@media only screen and (min-width:961px)and (max-width:1337px){
/*Large screen displays*/


}

