.feedback-wrapper .container {
    padding: 100px 20px;
   background: #eee;
}

.feedback-wrapper .container h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #1A1D1F;
    text-align: center;
    margin-bottom: 40px
}

.feedback-box {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: 40px 30px 40px 0
}

.feedback-box h4 {
    font-family: 'Inter', sans-serif ;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #1A1D1F;
}

.feedback-box h4 span {
    color: #9A9FA5;
    font-weight: 500
}

.feedback-box p {
    margin-top: 4px;
    color: #1A1D1F;
}

.feedb-img {
    background: #fff;
    padding: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    border-radius: 50%
}

.feedb-img img {
    border-radius: 50%
}


.feedback-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px
}


.feedb-time {
    position: absolute;
    right: 0;
    top: 40px
}

.feedback-action svg {
    fill: #9A9FA5;
    cursor: pointer
}

.feedback-action svg:hover {
    fill: #282D6C;
}

.feedback-wrap {
    max-width: 400px;
    margin: 0 auto
}


.feedback-wrap .feedback-box:not(:last-child) {
    border-bottom: 1px solid #a1a1ff
}


@media (max-width:767px){
    .feedback-wrapper .container{
        padding: 80px 15px 50px;
    }
}