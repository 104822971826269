.dashboard-section{
    background-color: #eeeeee;
    padding: 10px 15px 100px;
}

.dashboard-wrapper{
    max-width: 1440px;
    margin: 0px auto;
}
.Dashboard-user-info-wrapper{
    position: sticky;
    top: 15px;
}

.dashboard-wrapper .Overview > h2 {
   font-weight: 600;
   font-size: 17.8189px;
   line-height: 29px;
   letter-spacing: -0.02em;
   color: #33383F;
   margin-bottom: 7px
}

.overview-second-row-container > .css-mhc70k-MuiGrid-root {
    column-gap:10px;
    width: 100%;
    margin: 0;
   
    
   }
   
   .overview-second-row-container > .css-mhc70k-MuiGrid-root > div {
    width:calc(16.67% - 10px);
    flex-basis: auto;
    padding: 0
   }

@media only screen and (max-width: 1024px) { 
    .dashboard-section {
        padding: 10px 15px 80px;
    }


}



@media (max-width: 900px) { 
/*Medium screen displays*/
.overview-second-row-container > .css-mhc70k-MuiGrid-root > div {
    width: calc(33.33% - 10px);
    flex-basis: auto;
    padding: 0;
  margin: 0 5px 10px
}
 
 .overview-second-row-container > .css-mhc70k-MuiGrid-root{
  gap:0
 }
 
 

}

@media (max-width:767px){
    .dashboard-section {
        padding: 60px 15px 80px;
    }
}


@media (max-width:600px){
    
.overview-second-row-container > .css-mhc70k-MuiGrid-root > div {
    width: calc(50% - 10px);
}
}

@media (max-width:479px){
    .overview-second-row-container > .css-mhc70k-MuiGrid-root > div {
        width: 100%;
        margin: 0 0 10px;
        max-width: 100%;
    }

    .smaller-container{
        flex-direction: column;
    }

    .smaller-container-single {
        width: 100%;
        margin-bottom: 10px;
    }

    .overview-second-row-container{
        margin-top: 30px;
    }

    .dashboard-section .dashboard-wrapper > .css-11lq3yg-MuiGrid-root{
        display: block
       }
}