.user-info-container1{
    margin:0;
    padding: 20px;
    border-bottom: 1px solid #C4C2D3;
    background-color: #ffffff;
}
.theButtons{
    display: flex;
    flex-direction: column;
}
.user-info-container1 button{
    height: 41px !important;
    padding: 6px!important;
    display: flex !important;
    justify-content: center;
    border-radius: 5px !important;
    font-family: 'Inter' !important;
    font-size: 14px !important;
    font-style: normal !important;

}
button .MuiSvgIcon-root{
    margin: 0px 10px;
}
.user-info-container2{
    margin: 0px;
    background-color: inherit;
    padding: 20px;
    background-color: white;
    
}
.singleUser-info{
    display: flex;
    justify-content: space-between;
    margin:auto;
    padding: 2vh 10px;
    font-family: 'SF Pro Display';
    font-weight: 500;
    font-size: 16px;
    color: #64748B;
    border-radius: 5px;
}
.singleUser-info img{
    margin: 0px;
}
.singleUser-info:hover{
    cursor: pointer;
    background-color: #eeeeee;
    transition: 0.4s;
}
.singleUser-info p{
    margin: 0px;
}
.logout{
    margin: 0px;
    width: 130px;
}
.Gen-btn{
    background-color: #04063D !important;
    border:2px solid #04063D !important;
    
}
.Gen-btn:hover{
    color: #04063D !important;
    background-color: white !important;
}
.userName{
font-family: 'SF Pro Display' !important;
    font-weight: 500 !important;
    color:#0F172A !important;
}
.userTitle{
    font-family: 'SF Pro Display' !important;
    color: #94A3B8 !important;
    font-weight: 500 !important;
}

.user-info-container1-sub{
    text-align: center;
}
.user-data-wrapper{
    margin: 10px 40px 0 0;
}
.content-box{
    padding: 0px 20px;
}

@media only screen and (max-width: 600px) { 
/*Mobile screen displays*/
.user-info-container2{
    margin: 0px;
    width: 80%;
    background-color: inherit;
}
.singleUser-info{
    margin: 1vh;
    max-width: unset;
    
}
.singleUser-info img{
   margin-right: 20px;
}
.user-info-container2{
    padding: 0px;
    background-color: inherit;
}
.singleUser-info p{
    margin: 0px;
}
}





@media only screen and (max-width: 961px) and (min-width:600px){ 
/*Medium screen displays*/
.singleUser-info{
    margin: 10px;
}
.user-info-container1{
    padding: 0px;
    margin: auto;
    width: 85%;
}
.userAvatar{
    height: 30%;
    width: 30%;
}
}






@media only screen and (min-width:961px)and (max-width:1337px){
/*Large screen displays*/
.singleUser-info{
    margin: 0px;
}
.userAvatar{
    height: 30%;
    width: 30%;
}
}
    