.testi-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
}

.testi-box .testi-content {
    background: #fff;
    padding: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    border-radius: 10px;
}

.testi-section {
    background: #04063D;
    padding: 80px 0;
    position: relative;
}


.testi-section:after{
    position: absolute;
    width:150px;
    height:150px;
    background: url('../../../assets/quotes.png') no-repeat center;
    content:"";
     background-size: contain;
     top:30px;
     right:30px;
     opacity: 0.3;
}

.testi-section h1 {
    color: #fff;
    text-align: center;
}

.testi-name img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.testi-name {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 30px;
    justify-content: center;
}

.testi-name .testi-name-cont h4 {
    color: #fff;
}

.testi-name .testi-name-cont span {
    font-size: 14px;
    color: #fff
}

.testi-box .testi-content:after {
    position: absolute;
    top: auto;
    left: 39px;
    content: "";
    width: 0;
    height: 0;
    z-index: 1;
    border-style: solid;
    border-width: 15px 15px 0 15px;
    border-color: #fff transparent transparent transparent;
    bottom: -15px;
    left: 50%;
    transform: translatex(-50%);
}

.testi-box{
    width:calc(50% - 30px);
    margin: 0 15px 40px;
}

@media (max-width:767px){
    .testi-box{
        width:100%;
        margin: 0 0 30px;
    }

    .testi-wrap {
        margin-top: 40px;
    }

    .testi-section {
        padding: 50px 0;
    }

    .testi-section:after{
        width:100px;
        height: 100px;
    }
}

@media (max-width:600px){
    .testi-content p{
       font-size: 15px;
       line-height: 22px;
    }

    .testi-box .testi-content{
        padding: 25px 20px;
    }

    .testi-section:after{
        width:70px;
        height: 70px;
        right:20px;
        top:47px
    }
}
