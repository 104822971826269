.codeblock-Tabs .react-tabs__tab-list {
    padding: 0;
    list-style: none;
}

.codeblock-Tabs .react-tabs__tab-list li {
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
    color: #73779F
}

.codeblock-Tabs .react-tabs__tab-list li.react-tabs__tab--selected {
    color: #5A48FA;
}