.feedBack-container{
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    margin: 0px 0px;
    font-family: 'Inter';
    width: 100%;
    height: 100%;
}
.feedBack-container h2{
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 32px;
color: #1A1D1F;
}
.comments-container{
    height: fit-content;
}
.single-feedback-hero{
    width: 100%;
    display: flex;
    justify-content: left;
    margin: 4vh auto;
}
.single-feedback-child{
    margin-left: 15px;
    border-bottom: 1px solid #EFEFEF;
    width: 100%;
}
.userImage{
    margin-top: 10px;
    padding: 0px;
    transform: scale(1.2);
}

.single-feedback-child-header{
    display: flex;
    justify-content: space-between;
}
.feedBack-container p{
    margin: 0px;
}
.feedBack-container b{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 24px;
color: #1A1D1F;
}
.comment_body{
    margin: 15px 0px;
}
.single-feedback-child-footer{
    display: flex;
    justify-content: space-between;
    color:#9A9FA5;
    margin-bottom: 20px;
}
.feedBack-container h2{
    margin:0;
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 32px;
}

@media only screen and (max-width: 600px) { 
/*Mobile screen displays*/

.feedBack-container{
    width: 100%;
    padding: 30px;
    box-shadow: 0px 3.85586px 3.85586px rgba(0, 0, 0, 0.05);
}
}



@media only screen and (max-width: 961px) and (min-width:600px){ 
/*Medium screen displays*/
.feedBack-container{
    display: none;
}
}



@media only screen and (min-width:961px)and (max-width:1337px){
/*Large screen displays*/


}


