.abouts-wrapper {
    padding: 70px 0
}
.container li{
    list-style-type: disc;
    color: #220ddb;
}

.hero-about {
    padding: 80px 15px 30px;

}

.about-content {
    padding: 50px 0;
}

.about-content h3,
.about-content p {
    margin-top: 20px
}

.team-section {
    padding: 70px 0;
   
}

.team-wrap {
    display: flex;
    gap: 30px;
    padding-top: 30px;
    max-width: 800px;
    margin: 0 auto;

}

.team-wrap .col-left {
    max-width: 300px;
}

.col-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.team-wrap .col-right {
    width: calc(100% - 300px);
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.col-right p {
    margin-top: 10px
}


.team-wrap .col-right span {
    color: #5A48FA;
    font-weight: bold;
    font-size: 14px
}

.team-wrap.reverse {
    flex-direction: row-reverse
}

@media (max-width:767px) {

    .hero-about {
        padding: 40px 15px 20px;
    }

    .team-section {
        padding: 40px 0;
    }

    .team-wrap .col-left {
        max-width: 240px;
    }

    .team-wrap .col-right {
        width: calc(100% - 240px);
    }
}

@media (max-width:620px) {

    .team-wrap,
    .team-wrap.reverse {
        flex-direction: column;
        gap: 0;
        align-items: center;
    }

    .team-wrap .col-right {
        width: 100%
    }

    .team-wrap .col-left {
        max-width: 300px;
    }

    .col-right p {
        font-size: 14px;
        line-height: 1.3;
    }
}
