.contacts-wrapper {
    padding: 70px 0
}

.locations .col-left .text-wrap {
    margin-top: 20px
}

.two-columns {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
}

.two-columns .col-left {
    width: calc(40% - 30px)
}

.two-columns .col-right {
    width: calc(60% - 30px)
}

.map {
    width: 100%;
}

.contact-section {
    padding-top: 80px
 }
 
 .contact-form{
    max-width: 600px;
    margin: 0 auto
 }

 .contact-form input[type="submit"]{
    margin: 20px auto 0;
 }
 
@media (max-width:767px){
    .two-columns .col-right ,.two-columns .col-left {
        width: 100%;
    }
     
     .two-columns{
      flex-direction: column
     }
     
     .contacts-wrapper ,.contact-section {
        padding-top: 40px
    }
     
}
