div.single-market-item{
    background-color: white;
    border-radius: 9px;
    padding: 30px;
    margin: auto;
    margin-bottom: 30px;
    margin: 10px;
}
div.single-market-item>div.head{
    display: flex;
    justify-content:space-between;
}
p.name{
font-family: 'SF Pro Display';
font-style: normal;
font-weight: 600;
color:#1A202C;
width: 150px;
height: 25px;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
}
p.nickName{
    margin: 0px;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}
div.single-market-item img#itemPic{
    height: 50%;
    width: 100%;
    border-radius: 4px;
    margin: 15px 0px;
}
div.single-item-foot{
    display: flex;
    justify-content: space-between;
}

div.single-item-foot div{
    display: flex;
}
div.single-item-foot div p{
    margin: 0px;
    color:#64748B;
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    text-align:center;
    margin-top: 3px;
}

div.buy-now{
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}
div.buy-now button{
    max-width: unset;
    min-width: 100px;
    min-height: unset;
    max-height: unset;
    width: fit-content;
    padding: 10px;
    margin: 0px;
}
div.buy-now p{
font-family: 'SF Pro Display';
font-style: normal;
font-weight: 700;
color: #04063D;
font-size: 19px;
}

div.single-category{
    background-color: white;
    height: 10vw;
}
div.single-item-detailed{
    margin:20px 0px;
}
div.single-item-detailed div.detailed-pics{
    padding: 15px;
    padding-top: 0px;
}
div.detailed-pics >img{
    max-height: 360px;
    max-width: 100%;
    border-radius:10px;
}
.detailed-other-pics{
    display: flex;
    justify-content: space-between;
    height: fit-content;
    padding: 0px;
    margin: 22px 0px;
}
.detailed-other-pics img{
    /* max-width: 148px;
    max-height: 124px; */
    width: 130px;
    height: 110px;
    border-radius: 10px;
    margin: 0px;
    padding: 0px;
    display: block;
}

.detailed-other-pics-active{
    padding: 5px;
    border: 2px solid #3563E9;
    border-radius: 10px;
    margin: 0px;
}
.detailed-other-pics-active img{
    width: 120px;
    height: 100px;
}
.detailed-other-pics-inactive{
    padding: 0px;
    border-radius: 8px;
    height: fit-content;
}
div.single-item-detailed div.detailed-text{
    background-color: white;
    border-radius: 10px;
    padding: 20px;
}
div.detailed-head{
    display: flex;
    justify-content: space-between;
}
div.detailed-head >div h1{
    margin: 0px;
    color: #1A202C;
    margin-bottom: 10px;
    font-size: 32px;
}
div.detailed-head >div>div{
    display: flex;
    justify-content: space-between;
    margin: 0px;
    margin-bottom: 10px;
}
div.detailed-head >div>div p{
    margin: 2px 9px;
    color:#64748B;
    font-size: 14px;
}
p.desc-text{
    margin: 5px 0px;
    color:#596780;
    line-height: 40px;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Plus Jakarta Sans';
}
div.detailed-foot{
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
}
div.detailed-foot p{
    font-size: 19px;
    font-family: 'Plus Jakarta Sans';
    color:#90A3BF;
}
 .bData p{
    font-weight: 600;
    color: #596780 !important;
 }
div.detailed-foot h2{
    font-weight: 700;
    font-size: 28px;
    color: #1A202C;
}
div.detailed-foot button{
    margin: 0px 10px !important;
    max-width: unset !important;
    min-width: unset !important;
    width: 140px;
}
div.detailed-buy{
    display: flex;
    justify-content: space-between;
    margin: 0px;
}
div.detailed-buy >div{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eeeeee;
    width: 40px;
    border-radius: 5px;
}
div.comment-container{
    background-color: #fff;
    padding: 24px;
    margin-top: 40px;
    border-radius: 10px;
}
div.comment-container > div{
    margin: 20px 0px;
}
div.single-comment{
    height: fit-content;
    margin-bottom: 24px;
    font-family: 'Plus Jakarta Sans';
    
}
div.single-comment > div{
    display: flex;
    justify-content: space-between;
}
div.single-comment .com-name{
    font-weight: 700;
    font-size: 20px;
    margin: 8px 0px;
    line-height: 150%;
    color: #1A202C;
}
div.single-comment .com-title{
    font-weight: 500;
    color:#90A3BF;
    font-size: 14px;
}
div.single-comment .com-date{
    font-weight: 500;
    font-size: 14px;
    color:#90A3BF;
    text-align: right;
    margin: 10px 0px;
}
div.single-comment .com-body{
    color: #596780;
    font-size: 14px;
    font-weight: 400;
    line-height: 200%;
}
.com-showMore{
    margin: 0px auto !important;
    display: flex !important;
}
div.comment-container >p{
    color: #1A202C;
    font-weight: 600;
    font-size: 20px;
}
div.comment-container >p span{
    background-color:#3563E9;
    color: white;
    text-align: center;
    border-radius: 4px;
    padding: 6px 20px;
    margin: 0px 10px;
    font-size: 15px;
    font-weight: 700;
}



@media only screen and (max-width: 600px) { 
/*Mobile screen displays*/
div.single-market-item{
    max-width: unset;
    max-height: unset;
    padding: 17px;
    width: fit-content;
    height: 240px;
    height: fit-content;
    
}

div.below-head{
    display: flex;
    justify-content: space-between;
    margin: 0px;
}

div.single-market-item img#itemPic{
    height: 150px;
    width: 60%;
    margin-top: 5px;
}
div.single-item-foot{
    display:block;
    margin: 1vh;
}
div.single-item-foot div{
    margin: 15px 0px;
}
div.single-item-foot div p{
    font-size: 14px;
    margin: 0px;
}
div.buy-now{
    margin-top: 0px;
}
div.buy-now button{
    text-transform: none;
    width: 25%;
}
.detailed-other-pics img{
    width: 96px;
    height: 64px;
}
.detailed-other-pics-active{
    padding: 3px;
    margin: 0px;
}
.detailed-other-pics-active img{
    width: 88px;
    height: 56px;
}
div.single-item-detailed div.detailed-text{
    padding: 16px;
    margin: 0px 10px;
}
.detailed-chip{
    display: none !important;
}
div.detailed-head >div>div p{
    margin: 0px;
}
div.detailed-head >div h1{
    font-size: 20px;
}

div.detailed-foot p{
    font-size: 12px;
}
p.desc-text{
    font-size: 13px;
    line-height: 200%;
    color:#90A3BF;
    font-weight: 400;
}
div.detailed-foot h2{
    font-size: 24px;
    margin-top: 5px;
}
div.detailed-foot button{
    width: 120px;
    margin: 0px !important;
    margin-left: 5px !important;
}
div.comment-container{
    margin-top: 30px;
}
div.comment-container > div{
    margin: 20px 0px;
}
div.single-comment .com-name{
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
}
div.single-comment .com-title{
    font-weight: 500;
    color:#90A3BF;
    font-size: 12px;
}
div.single-comment .com-body{
    color: #90A3BF;
    font-size: 12px;
}
}






@media only screen and (max-width: 961px) and (min-width:600px){ 
/*Medium screen displays*/



}







@media only screen and (min-width:961px)and (max-width:1337px){
/*Large screen displays*/



}


@media only screen and (max-width: 899px) and (min-width:600px){ 
    /*Medium screen displays*/

    div.detailed-pics{
        display: flex;
        gap: 20px;
    }
    div.detailed-other-pics{
        margin: 0px;
        display: grid;
        grid-template-columns:1fr 1fr
        /* flex-direction: column;
        justify-content: space-around; */
    }
    div.detailed-pics >img{
        max-width: unset;
        max-height: unset;
        width: 60%;
        height: 60%;
    }
    .detailed-other-pics-active{
        margin: 5px;
    }
    .detailed-other-pics-inactive{
        margin: 5px;
    }
    
    }
