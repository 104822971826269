.overview-first-row-container{
    display: flex;
    padding: 0px;
    justify-content: space-around;
}
.overview-first-row-single-container{
    background-color: #ffffff;
    border-radius: 12px;
    margin: 0px;
    display: flex;
    justify-content: space-between;
    padding: 27px;
    height: 100%;
    align-items: center;
}
.overview-first-row-single-container span.ico{
    color: #5a48fb;
    background-color:#e6e7ff;
    padding: 10px;
    border-radius: 1000px;
    display: flex;
    width: fit-content;
    margin-bottom: 5px;
}
.overview-nameContainer{
    display: flex;
    justify-content: space-between;
    margin: 0px;
    padding: 0px;
}
.overview-nameContainer p{
    color: #04063D;
    font-weight: 600;
    padding-right: 1vh;
    font-family: 'Inter', "montserrat", sans-serif 
}
.overview-nameContainer div{
    background-color:#C4C2D3;
    border-radius: 1000px;
    display: flex;
    padding: 5px;
    margin: 0px;
    height: fit-content;
    width: fit-content;
}
.exclamIcon{
    transform: rotate(180deg);
    color: #ffffff;
    font-size:medium !important;
}
.overview-first-row-single-container h1{
    font-size: 40px;
    margin: 0px;
    padding: 0px;
    height: fit-content;
    width: fit-content;
    line-height: normal;
    color:#04063D;
}
.lineChartDiv{
    width: 100px;
    height: 100px;
    margin: auto;
}
.overview-second-row-container{
    padding: 0px;
    margin: 2vh 0px;
    height: fit-content;
}
.overview-second-row-single-container{
    background-color:#ffffff;
    border-radius:0px 0px 9.32674px 9.32674px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    height: 100%;
}
.overview-second-row-single-container h1{
    margin: 0px;
    padding: 0px;
    font-size: 22px;
    color: #64748B;
    margin-bottom: 3px;
}
.overview-second-row-single-container p{
    margin: 0px;
    padding: 0px;
    font-size: 11px;
    line-height: 15px;
}
.overview-second-row-single-container span {
    padding: 6px;
    border-radius: 1000px;
    display: flex;
    margin: 0px;
}

.smaller-container{
    display: flex;
   gap:10px
}
.smaller-container-single{
    width: 50%;
    padding: 12px;
    background-color: #ffffff;
    border-radius: 5px;
    margin-bottom:2vh;
}
.smaller-container p{
    margin: 0px;
    font-size: 16px;
    color: #04063D;
    font-weight: 500;
    line-height: 19px;
    font-family: 'SF-Pro-Display';
}
.smaller-container h3{
    margin: 0px;
    font-size: 24px;
    font-weight: 600;
    color: #04063D;
}
.trophy{
    display: flex;
    justify-content: space-between;
    max-width: 40px;
    margin: 5px 0px;
}
.trophy img{
    width: 40px;
    height: 40px;
}
.graph-container{
    height: fit-content;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 24px;
}
.graph-container p{
font-family: 'SF Pro Display';
font-style: normal;
font-weight: 500;
font-size: 20px;
color: #04063D;
margin: 0px;
}
.graph-container h1{
    font-size: 40px;
    line-height:1em;
    color: #04063D;
}
.line-container{
    background: #FFFFFF;
    box-shadow: 0px 3px 4px rgba(62, 73, 84, 0.04);
    border-radius: 10px;
    padding: 30px;
    max-width: 100%;
}
.line-container h2{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #04063D;
    padding-bottom: 60px;
}
.line-container > div{
    height: 350px;
    max-height: 390px;
    min-width: 100px;
    overflow: auto;
    width: 100%;
}
.disputed_resolved_hero_container{
    display: flex;
    justify-content: space-between;
    padding: 5px;
    margin-top: 22px;
}
.disputed_resolved_hero_container > div{
    display: flex;
    padding: 10px;
    border-radius: 0px 0px 9.32674px 9.32674px;
    width: calc(50% - 10px);
    justify-content: space-between;
    gap:5px;
}

.disputed_resolved_hero_container p{
    font-size: 11px;
    margin: 0px;
}


.disputed_resolved_right{
    padding: 7px;
    border-radius: 1000px;
    display: flex;
    width: fit-content;
    height: fit-content;
    align-content: center;
    margin-top:10px;
}

.ovrow1-data-trend{
    color: #8E59FF;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    background-color: #efedff;
    text-align: center;
    padding: 5px 12px;
    border-radius: 5px;
    margin: 0px;
    justify-content: space-between;
    gap: 4px;
    margin-top: 10px;
}
.ovrow1-data-trend span{
    margin-top: auto;
    margin-top: auto;
}




@media only screen and (max-width: 600px) { 
/*Mobile screen displays*/
.overview-first-row-contaier{
    display: block;
    margin-bottom: 25px;
}
.lineChartDiv{
    margin-right: 10px;
}
.overview-second-row-single-container{
    box-shadow:3px 5.45645px 5.4582px 2.4582px  #eeeeee;
    padding: 10px;
    max-height: 76px;
    max-width: unset;
}
.overview-second-row-single-container h1{
    font-size: 25px;
}

.smaller-container-single{
    max-width: unset;
    border:1px solid #D5D0FD;
    box-shadow:0px 2.89381px 5.85841px 2px #eeeeee;
    padding: 8px;
}

.graph-container{
    box-shadow: 0px 5.24959px 10.0617px rgba(62, 73, 84, 0.04);
    border: 1px solid #D5D0FD;
    padding: 30px;
    width: 100%;
    margin: 23px auto;
}
.TheBar{
    max-width:280px;
    margin: 25px 0px;
}
.line-container{
box-shadow: 0px 2.89381px 3.85841px rgba(62, 73, 84, 0.04);
width: 99%;
margin: 20px 0px;
}
.disputed_resolved_hero_container {
    margin: 0px;
    padding: 0px;
    margin-bottom: 20px;
}
.disputed_resolved_hero_container > div{
    padding: 10px;
}
.disputed_resolved_right{
    margin-top: 10px;
}
.disputed_resolved_left h2{
    font-size: 20px;
    margin-bottom: 1vh;
}
.disputed_resolved_left p{
    font-size: 11px;
}
.disputed_resolved_left{
    margin-right: 1vh;
}
.ovrow1-data-trend span{
    font-size: 10px;
}

}










@media only screen and (max-width: 961px) and (min-width:600px){ 
/*Medium screen displays*/
.overview-first-row-container{
    margin: 1vh;
    
}
.overview-first-row-single-container{
    border:1px solid #D5D0FD;
    box-shadow:0px 2.89381px 5.85841px 2px #eeeeee;
    padding: 10px;
}
.overview-second-row-container{
    width: fit-content;
    margin: auto;
    margin-top: 10px;
}
.smaller-container{
    display: block;
}
.smaller-container-single{
    width: 100%;
}
.graph-container{
    padding: 10px;
}
}






@media only screen and (min-width:961px)and (max-width:1337px){
/*Large screen displays*/
.overview-first-row-container{
    margin: 1vh;
    
}
.overview-first-row-single-container{
    border:1px solid #D5D0FD;
    box-shadow:0px 2.89381px 5.85841px 2px #eeeeee;
    padding: 10px;
}
.graph-container{
    padding: 10px;
}


}


@media (max-width:1024px){
 
    .overview-first-row-contanier >div >div{
    width:33.33%;
    flex-basis: 100%;
    max-width: 33.33%
   }
 
    
    .overview-first-row-single-container{
     padding: 20px
    }
    
    .overview-first-row-single-container h1 {
       font-size: 30px;
    }
    
    .ovrow1-data-trend{
     font-size: 12px
    }
    
    .ovrow1-data-trend > svg{
        width: 20px
    }
}
   

@media (max-width:900px){
    .overview-first-row-contanier >div >div{
   width:50%;
   max-width:50%
  }
   

  }

  @media (max-width:600px){
    .overview-first-row-contanier >div >div{
   width:100%;
   max-width:100%
  }

  .overview-first-row-single-container{
    gap:30px
  }

  .lineChartDiv {
    width: 100px;
    height: 100px;
    margin: 0;
}

   
  
  }


  @media (max-width:479px){

    .disputed_resolved_hero_container{
        flex-direction: column;
        gap:10px
    }
    .disputed_resolved_hero_container > div{
        width: 100%;
    }

    .smaller-container{
        flex-direction: column;
    }

    .smaller-container-single {
        width: 100%;
        margin-bottom: 10px;
    }
  }