/*NB: Mobile is as from 600px downwards!*/

@font-face {
    font-family: "SF-Pro-Display";
    src: local("SF-Pro-Display"),
        url("./fonts/sf-pro/sf-bold.woff") format("woff");
    src: local("SF-Pro-Display"),
        url("./fonts/sf-pro/sf-bold.woff2") format("woff2");
    font-weight: bold;
}

@font-face {
    font-family: "SF-PRO-Display";
    src: local("SF-Pro-Display"),
        url("./fonts/sf-pro/sf-med.woff") format("woff");
    src: local("SF-Pro-Display"),
        url("./fonts/sf-pro/sf-med.woff2") format("woff2");
    font-weight: 500;
}

@font-face {
    font-family: "SF-Pro-Display";
    src: local("SF-Pro-Display"),
        url("./fonts/sf-pro/sf-reg.woff") format("woff");
    src: local("SF-Pro-Display"),
        url("./fonts/sf-pro/sf-reg.woff2") format("woff2");
    font-weight: 400;
}

@font-face {
    font-family: "montserrat";
    src: local("montserrat"),
        url("./fonts/mont/Montserrat-Bold.woff") format("woff");
    src: local("montserrat"),
        url("./fonts/mont/Montserrat-Bold.woff2") format("woff2");
    font-weight: bold;
}

@font-face {
    font-family: "montserrat";
    src: local("montserrat"),
        url("./fonts/mont/Montserrat-ExtraBold.woff") format("woff");
    src: local("montserrat"),
        url("./fonts/mont/Montserrat-ExtraBold.woff2") format("woff2");
    font-weight: 800;
}

@font-face {
    font-family: "montserrat";
    src: local("montserrat"),
        url("./fonts/mont/Montserrat-ExtraLight.woff") format("woff");
    src: local("montserrat"),
        url("./fonts/mont/Montserrat-ExtraLight.woff2") format("woff2");
    font-weight: 200;
}

@font-face {
    font-family: "montserrat";
    src: local("montserrat"),
        url("./fonts/mont/Montserrat-Light.woff") format("woff2");
    src: local("montserrat"),
        url("./fonts/mont/Montserrat-Light.woff2") format("woff2");
    font-weight: 300;
}

@font-face {
    font-family: "montserrat";
    src: local("montserrat"),
        url("./fonts/mont/Montserrat-Regular.woff") format("woff");
    src: local("montserrat"),
        url("./fonts/mont/Montserrat-Regular.woff2") format("woff2");
    font-weight: 400;
}

@font-face {
    font-family: "montserrat";
    src: local("montserrat"),
        url("./fonts/mont/Montserrat-Medium.woff") format("woff");
    src: local("montserrat"),
        url("./fonts/mont/Montserrat-Medium.woff2") format("woff2");
    font-weight: 500;
}

@font-face {
    font-family:"montserrat";
    src: local("montserrat"),
        url("./fonts/mont/Montserrat-SemiBold.woff") format("woff");
    src: local("montserrat"),
        url("./fonts/mont/Montserrat-SemiBold.woff2") format("woff2");
    font-weight: 600;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

/* font-family: 'Poppins', sans-serif; */

/* html,body{
    overflow-x: hidden;
} */

h1, h2,h3,h4,h5,h6{
    margin: 0;
    color: #282D6C;
}
body p{
  margin: 10px 0 0 0;
}


ol li{
    margin-bottom: 3px;
}
.Bigh1-72{
    font-family:"SF-Pro-Display";
    font-style: normal;
    font-weight: 600;
    font-size: 72px;
}
.smallh1-48{
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    color: #04063D;
    line-height: 64px;
}
.Bigh2-34{
    font-family: 'SF-Pro-Display';
    font-weight: 600;
    font-size: 34px;
}
.h3-22{
    font-family: 'SF-Pro-Display';
    font-size: 22px;
    font-weight: 600;
    color:#1D293F;
    margin: 0px;
    padding: 0px;
    font-style:normal ;
}
.smallh2-12{
    font-size: 12px;
    font-weight: 600;
    font-family: 'SF-Pro-Display';
    color: #282D6C;
}
.MuiChip-root{
    color:#5A48FA !important;
    background-color: #dcfbff !important;
    width: fit-content !important;
}
.p-14{
    padding: 0px;
    margin: 0px;
    font-family:"montserrat", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}
.p-16{
    padding: 0px;
    margin: 0px;
    font-family:"montserrat", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #7C8087;
    line-height: 28px;
}
.p-18{
    padding: 0px;
    margin: 0px;
   font-family:"montserrat", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: #282D6C;
}

.heading{
    margin: 0px;
}
.centerd{
    width: fit-content;
    margin: 0px auto;
}
body {
    font-family: 'Inter', "montserrat", sans-serif !important;
    font-weight: 400;
    background: #F5F5F5;
    color: #73779F;
}

body p{
    color: #73779F;
    font-family: 'Inter', "montserrat", sans-serif !important;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
}

ul , ol{
    padding-left: 20px
}

ul li{
    list-style: inherit;
    color:#220ddb
}
   
 ul li::marker{
    color:#5A48FA
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

.text-container {
    max-width: 700px;
    text-align: center;
    margin: 0 auto 50px
}

.text-container p {
    margin-top: 10px;
    color: #73779F
}


.text-container span {
    color: #5A48FA;
    font-weight: bold;
}

.text-container h1 {
    font-family: "SF-PRO-Display", "montserrat", "poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 64px;
}

body .text-container button[type="submit"]{
    margin-top: 20px;
}
body a {
    cursor: pointer;
}
.hero-sect{
    position: relative;
}

.hero-sect:after {
    border-bottom: 1px solid #9ba8e9;
    content: "";
    width: 66vw;
    bottom: 0;
    left: 50%;
    transform: translatex(-50%);
    height: 1px;
    position: absolute
}
body .btn,
body button[type="submit"],
body input[type="submit"] {
    background: #5A48FA;
    border-radius: 4px;
    display: inline-block;
    color: #fff;
    padding: 11px 12px 12px;
    min-width: 229px;
    text-align: center;
    text-decoration: none;
    transition: 0.3s all;
    border: 2px solid #5A48FA;
    outline: none;
    font-weight: 600;
    font-size: 15.6137px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.0975853px;
    cursor: pointer;
    margin-top: 10px;
    font-family:"montserrat" , sans-serif;  

}

body .btn:hover,
body button[type="submit"]:hover,
body input[type="submit"]:hover {
    background: transparent;
    color: #5A48FA;
    text-decoration: none;
}


body .trans-btn.btn,
body .trans-btn button[type="submit"],
body .trans-btn input[type="submit"] {
    background: transparent;
    color: #5A48FA;
}


body .trans-btn.btn:hover,
body .trans-btn button[type="submit"]:hover,
body .trans-btn input[type="submit"]:hover {
    background: #5A48FA;
    color: #fff;
}

body input[type="email"],
/* body input[type="number"], */
body input[type="password"],
body input[type="confirm-password"],
body input[type="text"],
body input[type="tel"],
body input[type="date"],
body select,
textarea {
    background: transparent;
    border: 0.975853px solid #EAEAF0;
    border-radius: 7.80683px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13.6619px;
    line-height: 100%;
    color: #B4B6CC;
    padding: 16px 20px;
    display: block;
    margin-bottom: 10px;
    width: 100%;
    outline: none;
    font-family:"montserrat" , sans-serif; 
}

body input[type="email"]:focus,
/* body input[type="number"]:focus, */
body input[type="password"]:focus,
body input[type="confirm-password"]:focus,
body input[type="text"]:focus,
body input[type="tel"]:focus,
textarea:focus {
    border-color: #5A48FA
}

body input::placeholder,
textarea::placeholder {
    color: #B4B6CC
}


textarea {
    resize: none;
    height: 200px;
}

form input[type="submit"] {
    margin-top: 26px;
    display: block;
}

form label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13.6619px;
    line-height: 160%;
    letter-spacing: 0.0975853px;
    color: #5E6191;
    margin-bottom: 7px;
    display: block
}

form .check {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
form .check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
form .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: transparent;
    border: 2px solid #64748B;
    border-radius: 6px;
    margin-top: 10px;
}


/* Create the checkmark/indicator (hidden when not checked) */
form .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
form .check input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
form .check .checkmark:after {
    left: 6px;
    top: 1px;
    width: 3px;
    height: 8px;
    border: solid #5A48FA;
    border-width: 0 2.5px 2.5px 0;
    transform: rotate(45deg);
    padding: 2.5px;
}


.check-cont {
    display: flex;
    align-items: flex-start;
    gap: 10px
}

.check-cont p {
    font-weight: 400;
    font-size: 13.6619px;
    line-height: 160%;
    letter-spacing: 0.0975853px;
    color: #04063D;
    font-family: 'Poppins', sans-serif;

}

.check-cont {
    margin-bottom: 20px;
    position: relative
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Some browsers will not display the caret when using calc, so we put the fallback first */
    /* background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat 20% !important; */
    /* !important used for overriding all other customisations */
    background: url("./assets/dropd.png") white no-repeat calc(100% - 10px) !important;
    /* Better placement regardless of input width */
}

/*For IE*/
select::-ms-expand {
    display: none;
}

select option,
select option.disable {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.305608px;
    color: #B0B0B0;
    font-family: "SF-Pro-Display";
    font-weight: 500;
}

select:focus {
    outline: 1px solid #5A48FA
}
.col-50.col {
    width:calc(50% - 20px);
}
.col-33.col {
  width:calc(33.33% - 20px)

}
.col-100.col {
  width:100%;
}

.col{
  display: block;
  margin: 0 10px 10px;
}
.userAvatar{
    margin: 0vh auto 1vh auto;
    height: 7vh;
    width: 7vh;
}

@media (max-width:1024px){
    .Bigh1-72{
        font-size: 60px;
    }
}

@media (max-width:900px){
    .Bigh1-72{
        font-size: 44px;
    }
}
@media (max-width:600px) {
/*Mobile displays*/
    .MuiChip-root { 
        color:#5A48FA !important;
        background-color:#dbfaff !important;
        margin-left: auto !important;
        margin-right: auto !important;
        font-weight: 500;
        font-family: 'Inter';
    }
    .smallh1-48{
        font-size: 30px;
        line-height: 35px;
    }
    .p-16{
        font-size: 14px;
    }
    
    .p-18{
        font-size: 16px;
    }
    .heading{
            width: fit-content;
            margin: 15px ;
    }
    .centerd{
        width: fit-content;
        margin: auto;
    }
   .Bigh2-34{
    font-size: 24px;
   }
   .Bigh1-72{
    font-size: 39px;
}

}
@media (max-width:767px) {
    .text-container h1 {
        font-size: 34px;
        line-height: 44px;
    }

    .text-container {
        margin-bottom: 30px;
    }
}


@media (max-width:1300px) {



}

@media (max-width:1330px) {

}

@media (max-width:1200px){

}

@media (max-width:1024px) {

    
}


@media (max-width:600px) {
body{
    padding-top: 50px !important;
}


    
}
