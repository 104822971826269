@import '../../../App.css';

h1,
h2,
h3,
h4,
h5,
p {
    margin: 0;
}

.msg-main-wrap>h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 17.8189px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #33383F;
    margin-bottom: 10px
}

.msg-wrapper {
    padding: 15px;
    background: #FFFFFF;
    border-radius: 8px;
    min-height: 773px;
}

.profile {
    padding: 12px;
    background: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 12px;
    transition: 0.2s all;
}

.profile:hover {
    background: #F4F4F4;
}

.profile .profile-img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: #eee;
    position: relative
}

.profile-img .profile-active {
    background: #83BF6E;
    border: 4px solid #EFEFEF;
    border-radius: 12px;
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.profile .profile-content {
    width: calc(100% - 56px);
    padding-left: 10px;
}

.profile .profile-content .profile-name {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #1A1D1F;
    display: flex;
    gap: 30px;
    align-items: center;
    position: relative;
    padding-right: 20px;
    justify-content: space-between;
    text-transform: capitalize;
}

.chat-time {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #9A9FA5;
}

.online-status {
    background: #9A9FA5;
    border-radius: 12px;
    width: 14px;
    height: 14px;
    display: block;
    position: absolute;
    top: 4px;
    right: 0
}

.online-status.active {
    background: #5A48FB;
}

.profile .profile-content .profile-desc p {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
    color: #6F767E;
    margin: 0
}

.search-chat {
    background: #F4F4F4;
    border-radius: 12px;
    padding: 12px 12px 12px;
    margin-top: auto;
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
}

.search-chat input {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #9A9FA5;
    border: 0;
    background: transparent;
    outline: none;
}

.state-btns {
    display: flex;
    background: #F4F4F4;
    border-radius: 40px;
    padding: 5px;
    margin-bottom: 26px;
}

.sta-btn {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #6F767E;
    padding: 10px 15px;
    border-radius: 32px;
    cursor: pointer;
    position: relative;
}

.sta-btn i {
    padding-right: 8px;
}


/* Button/01 */
.sta-btn.active {
    color: #1A1D1F;
    background: #FCFCFC;
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
}

 .chat-user-no {
    background: red;
    position: absolute;
    padding: 3px;
    color: #fff;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 10px;
   
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    display: none;
}

.sta-btn.active  .chat-user-no{
    display: flex;
}

.profchat-tab {
    background: #fff
}

.profchat-tab .chat-topbar h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #04063D;
}

.chat-subdes {

    font-family: 'SF Pro Display';
    font-weight: 500;
    font-size: 10px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #73779F;
    display: inline-block;
    padding: 0 4px 0 0
}

.chat-subdes b {
    font-weight: bold
}

.chat-paybtn button {
    font-family: "SF-PRO-Display", "montserrat", "poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 10.1869px;
    line-height: 12px;
    letter-spacing: -0.305608px;
    border: none;
    color: #FFFFFF;
    padding: 10px;
    background: #5A48FB;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s all;
    border: 2px solid #5A48FB;
}

.chat-paybtn button:hover {
    background: transparent;
    color: #5A48FB;
}

.chat-paybtn i {
    margin-right: 5px;
}

.msg-wrapper {
    display: flex;
}

.msg-wrapper .msg-profiles {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 0 100px;
}

.msg-wrapper .profchat-tab {
    width: calc(100% - 300px);
    padding-left: 30px;
}

.profchat-tab .chat-topbar {
    background: #F4F4F4;
    border-radius: 12px 12px 0px 0px;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profchat-tab .chat-paybtn {
    display: flex;
    align-items: center;
    gap: 8px
}

.chatting-tab .profiles {
    min-height: 637px;
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    border-radius: 0px 0px 12px 12px;
    display: flex;
    flex-direction: column;
}

.chatting-tab .profile .profile-name {
    padding-right: 0;
    gap: 20px;
    text-transform: capitalize;
    justify-content: flex-start;
    color: #6F767E;
}

.chatting-tab .profile.supporter-chat .profile-content {
    padding: 0 0 0 10px;
}

button.load-convers {
    background: #FFFFFF;
    border: 2px solid #EFEFEF;
    border-radius: 8px;
    padding: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #1A1D1F;
    outline: none;
    cursor: pointer;
    margin: 24px auto;
    min-width: 148px;
    display: block;
    transition: 0.3s all;
}

button.load-convers:hover {
    box-shadow: 0 0 4px rgb(0 0 0 / 10%);
}

.fa-magnifying-glass {
    cursor: pointer;
    color: #6F767E
}

.reply-buttons button {
    outline: none;
    border: none;
    cursor: pointer;
    transition: 0.3s all;
    background: none
}

.reply-buttons button i {
    font-size: 15px
}

.reply-buttons button:hover {
    opacity: 0.7
}

.reply-buttons button.send {
    background: #5A48FB;
    border-radius: 8px;
    padding: 8px 16px;
    color: #fff;
    transition: 0.3s all
}


.reply-buttons button.send:hover {
    opacity: 0.9
}


.reply-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    padding: 30px 24px 24px
}

.send-buttons {
    max-width: 100px;
    width: 100%
}


.reply-buttons .send-field {
    background: #F4F4F4;
    border: 2px solid #EFEFEF;
    border-radius: 12px;
    padding: 10px;
    width: calc(100% - 100px);
    display: flex;

}


.reply-buttons .send-field textarea {
    width: calc(100% - 80px);
    box-sizing: border-box;
    border: none;
    outline: none;
    padding: 7px 10px 0 0;
    resize: none;
    min-height: auto;
    vertical-align: middle;
    background: none;
    min-height: 24px;
    height: 24px;
    line-height: 17px;
    font-size: 14px;
}


textarea::-webkit-scrollbar {
    display: none;
}

.reply-buttons .send-field button {
    min-width: 80px;
}

.msg-center-section {
    display: flex;
    margin: 0 auto;
    max-width: 1230px;
    padding: 10px 15px;
}

.msg-main-wrap {
    width: calc(100% - 237px);
    height: auto;
}

.message-side-bar {
    min-width: 237px;
    padding-right: 40px
}

.message-side-bar>div {
    margin: 0;
    width: 100%;
}

body .message-side-bar .css-4lr2ng-MuiAvatar-root-UserInfo {
    max-width: 70px;
    width: 100%;
    height: 100%;
}


body .message-side-bar .css-11kpzwm-UserActions {
    padding: 30px 10px 30px 10px;
}

body .message-side-bar .css-1u4pv04-UserActions,
body .message-side-bar .css-1fisuk1-UserActions {
    justify-content: flex-start;
    position: relative;
    padding: 10px 20px;
    align-items: center;
}

body .message-side-bar .css-1u4pv04-UserActions:hover {
    background-color: #e6e5f8;
    border-radius: 10px;
    cursor: pointer
}

body .message-side-bar .css-1fisuk1-UserActions {
    margin: 0;
    display: flex;
    gap: 15px;
    cursor: pointer
}

.css-1u4pv04-UserActions .css-4ozks-MuiChip-root-UserActions {
    position: absolute;
    right: 10px;
    top: 10px;
}

body .message-side-bar .css-1ixehq1-UserActions {
    margin: 0;
}

body .message-side-bar .css-7lobfq-UserActions {
    text-align: left;
}

body .css-11kpzwm-UserActions {
    background: #fff
}

body .message-side-bar .css-1u4pv04-UserActions:hover {
    background-color: #e6e5f8;
    border-radius: 10px;
}

body .message-side-bar .css-1u4pv04-UserActions:hover .css-4ozks-MuiChip-root-UserActions {
    background: #fff
}

.profile-img img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    border-radius: 50%;
}



span.chat-date {
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #9A9FA5;
}


.chatting-tab .profile {
    align-items: flex-start;
background: #F2F2F2;
border-radius: 0 20px 20px 20px;
margin: 10px auto 18px 10px ;
max-width: 350px;
padding: 14px 10px

}

.chatting-tab .profile .profile-desc{
   display: flex;
   align-items: flex-end;
}

.chatting-tab .profile.supporter-chat {
   margin: 10px 10px 18px auto;
  border-radius: 20px 20px 0px 20px;
   background: #5A48FB

}

.chatting-tab .profile.supporter-chat .profile-name ,  .chatting-tab .profile.supporter-chat .profile-content .profile-desc p {
color:#fff
}

.chatting-tab .profile.supporter-chat .profile-content .profile-desc p a{
color:#000
}


.chatting-tab .profile.supporter-chat .profile-content .profile-desc .chat-time{
 color:#fff
}

/*  Responsive  */
@media (max-width:1150px) {
    .message-side-bar {
        min-width: 200px;
        padding-right: 20px;
    }

    .msg-main-wrap {
        width: calc(100% - 200px);
    }

    .msg-wrapper .profchat-tab {
        padding-left: 10px;
    }

    .profile .profile-content .profile-desc p {
        font-size: 13px;
        line-height: 22px;
    }

    .profchat-tab .chat-topbar h3 {
        font-size: 14px;
        line-height: 17px;
    }

    .chat-paybtn button {
        padding: 6px;
    }

    .profchat-tab .chat-topbar {
        padding: 12px;
    }

    .sta-btn {
        font-size: 13px;
        line-height: 17px;
    }

    .reply-buttons button.send {
        padding: 8px 10px;
        min-width: 55px;
    }

    .reply-buttons .send-field textarea {
        width: calc(100% - 55px);
    }

    .reply-buttons {
        padding: 14px 15px;
    }

    .profile .profile-content .profile-name {
        font-size: 13px;
    }

    .profchat-tab .chat-paybtn {
        gap: 1px;
    }

    .send-buttons {
        max-width: 80px;
    }

    .reply-buttons .send-field {
        width: calc(100% - 80px);
    }

}

@media (max-width:1050px) {
    .chat-subdes {
        font-size: 9px;
        margin-top: 5px;
    }
}

@media (max-width:1024px){
    body .message-side-bar .css-1u4pv04-UserActions, body .message-side-bar .css-1fisuk1-UserActions {
       gap: 14px;
       margin: 0;
     }
    
    body .message-side-bar .css-1vy4zbw-UserInfo , body .message-side-bar .css-11kpzwm-UserActions{
      padding: 30px;
   }
   
   body .message-side-bar .css-1vy4zbw-UserInfo button{
     max-width: 350px;
     margin: 15px auto ;
     text-align: center;
     display: flex;
     gap: 10px;
   }
   
    .msg-main-wrap {
       width: 100%
   }
    
    .chat-paybtn button {
       padding: 10px;
   }
    
    body .message-side-bar .css-1vy4zbw-UserInfo{
     margin: 0 !important
    }
   

    body .css-1u4pv04-UserActions .css-4ozks-MuiChip-root-UserActions {
        top: 6px;
        background: #edebff;
    }

    .msg-center-section{
        flex-direction: column;
        gap:30px
    }

    .message-side-bar {
        padding-right: 0;
    }
}

@media (max-width:767px) {
    .msg-wrapper .msg-profiles {
        max-width: 100%;
    }

    .msg-wrapper .profchat-tab {
        width: 100%;
        padding: 30px 0 0;
    }

    .msg-wrapper {
        flex-direction: column;
    }

    .message-side-bar {
        max-width: 500px;
        padding: 0 0 40px 0;
        margin: 0 auto;
        width: 100%;
    }

    .msg-center-section {
        flex-direction: column;
    }

    body .message-side-bar .css-1vy4zbw-UserInfo, body .message-side-bar .css-11kpzwm-UserActions {
        padding: 20px 10px;
    }

    .smily-btn{
        display: none;
    }


}

@media (max-width:600px) {
    .reply-buttons .send-field {
        width: 100%;
        margin-top: 10px;
    }

    .reply-buttons {
        flex-direction: column;
        align-items: flex-start;
    }

    .profchat-tab .chat-topbar {
        padding: 12px;
    }
}

@media (max-width:479px) {
    .chat-subdes {
        font-size: 10px;
        margin-top: 3px;
        display: block;
    }

    .profchat-tab .chat-paybtn {
        gap: 8px;
    }
}
