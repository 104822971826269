div.admin-total-users-container{
    /* min-width: 672px;
    max-width: 672px; */
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    margin-right: 20px;
}
div.admin-total-users-container p{
    margin: 0px;
}
div.admin-total-users-container div.head{
    display: flex;
    justify-content: space-between;
}
div.admin-total-users-container div.head >div{
    display: flex;
    gap: 5px;
    align-items: center;
    color: #6F767E;
    padding: 8px;
    border: 2px solid #EFEFEF;
    border-radius: 12px;
}
div.admin-total-users-container div.head >div:hover{
cursor: pointer;
background-color: #eeeeee;
transition: 0.4s;
border: 2px solid #6F767E;
}
div.admin-total-users-container div.head >div p{
    font-size: 14px;
    color: #6F767E;
}
div.head >p{
    font-size: 20px;
    color: #1A1D1F;
    font-weight: 600;
}
/*---------------------*/

/*-------Below Head----------*/
div.below-head{
    color: black;
}
/*--------------------------*/

div.total-users-container{
    /* display: flex;
    justify-content: space-between; */
}
div.admin-total-users-container div.trend-details{

}
div.multiple-admin-escrow-container{
    
}
div.multiple-admin-escrow-container>div{
    max-width: 170px;
    max-height: 80px;
    border-radius: 0px 0px 9.49852px 9.49852px;
    display: flex;
    justify-content: space-between;
    padding: 14px;
    margin-bottom: 19px;
}
div.single-escrow-type h2{
    font-size: 27px;
    color: inherit;
    font-weight: 600;
    line-height: 40px;
    font-family: 'Poppins'
}
div.single-escrow-type p{
    color: inherit;
    line-height: normal;
    margin: 0px;
    font-size: 11px;
    font-family: 'SF Pro Display'
}
div.single-escrow-type div.single-escrow-right>div{
    height: fit-content;
    width: fit-content;
    border-radius: 45px;
    display: flex;
    padding: 9px;
}
div.admin-total-users-container div.trend-details p{
    font-weight: 700;
    font-size: 13px;
}
div.admin-total-users-container div.trend-details span{
    color: #83BF6E;
    background-color: #EAFAE5;
    border-radius: 4px;
    margin: 0px 5px;
    padding: 5px;
}

@media only screen and (max-width: 600px) {
    div.admin-total-users-container{
        min-width: unset;
        max-width: unset;
        margin: 0px;
    }
    div.admin-total-users-container div.head{
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
    div.admin-total-users-container div.head>div{
        justify-content: space-between;
    }
    div.admin-total-users-container div.trend-details{
        margin: 20px auto;
    }
    div.multiple-admin-escrow-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: auto;
        margin-top: 20px;
    }
}
@media only screen and (max-width: 961px) and (min-width:600px) {
    div.admin-total-users-container{
        min-width: unset;
        max-width: unset;
        
    }

}
@media only screen and (min-width:961px)and (max-width:1337px) {
    div.admin-total-users-container{
        min-width: unset;
        max-width: unset;
    }
    /* div.multiple-admin-escrow-container{
        display: grid;
        grid-template-columns: 1fr 1fr;

    } */
}
@media (max-width:767px){
    div.admin-total-users-container{
        min-width: unset;
        max-width: unset;
    }

}
