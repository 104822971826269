.columns-wrap {
    display: flex;
    column-gap: 30px
}

.columns-wrap .col {
    width: 50%
}

.columns-wrap .col.col-right img {
    width: 100%;
}

.apiHero {
    padding: 50px 0;
}

.columns-wrap .col-left {
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center
}

.columns-wrap .col-left h2 {
    font-size: 34px;
    line-height: 47px;
    color: #282D6C;
}

.columns-wrap .col-left h1 {
    font-size: 40px;
    color: #282D6C;
}

.columns-wrap .col-left p {
    margin-top: 20px
}

.buttons-wrap {
    display: flex;
    gap: 15px;
    margin-top: 20px;
}

.buttons-wrap .btn,
.buttons-wrap button[type="submit"] {
    min-width: 198px;
}

.icons-section {
    padding: 60px 0 30px;
    background: rgb(239 239 239 / 40%)
}

/*--icons-module --*/

.icons-columns-wrap {
    display: flex;
    flex-wrap: wrap;
}

.icons-columns-wrap .col {
    width: calc(25% - 30px);
    margin: 0 15px 30px
}

.icons-columns-wrap .col .icon-img i {
    font-size: 24px;
    color: #282D6C
}

.icons-columns-wrap .col h5,
.icons-columns-wrap .col p {
    margin-top: 10px
}

.icons-columns-wrap .col h5 {
    font-size: 18px;
    color: #282D6C
}

.icons-columns-wrap .col p {
    font-size: 14px;
    color: #73779F
}

/*cta-module*/
.cta-section {
    padding: 80px 0;
}

.cta-column-wrap .cta-col {
    max-width: 770px;
    margin: 0 auto;
    text-align: center
}

.cta-col h2 {
    font-size: 40px;
    line-height: 47px;
}

.cta-col p {
    margin-top: 15px
}

.buttons-wrap.btn-center {
    justify-content: center
}

.cta-img {
    max-width: 900px;
    margin: 40px auto 0
}

.cta-img img {
    width: 100%
}

.img-cta-section {
    padding: 80px 0;
    background: rgb(239 239 239 / 40%)
}

/* two column reverse*/

.columns-wrap.reverse-column-wrap {
    flex-direction: row-reverse
}

.columns-wrap.reverse-column-wrap .col-left {
    padding: 0 0 0 30px
}

/*api-fetares-section*/

.api-features-section {
    padding: 80px 0;
}

.api-features-section .icons-columns-wrap {
    margin-top: 70px
}

.api-features-section .icons-columns-wrap .col {
    width: calc(25% - 30px);
}

.devloper-tab {
    max-width: 1300px;
    margin: 0 auto;
}

.devloper-tab>.react-tabs {
    display: flex;

}

.devloper-tab>.react-tabs>.react-tabs__tab-list {
    max-width: 260px;
    padding: 0;
    margin: 0;
    list-style: none;
    background: #e8eef2;
    min-height: 100vh;
    padding: 20px;
    width: 100%;
}

.devloper-tab>.react-tabs>.react-tabs__tab-list .react-tabs__tab {
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
}

.devloper-tab>.react-tabs>.react-tabs__tab-panel.react-tabs__tab-panel--selected {
    width: calc(100% - 260px);
    padding: 30px
}

.devloper-tab>.react-tabs>.react-tabs__tab-list .react-tabs__tab:not(.main-tab-hd) {
    margin-bottom: 5px;
    font-size: 16px;
    color: #333;
    padding-left: 30px;
    outline: none;
    cursor: pointer;
    font-weight: 400;

}

.devloper-tab>.react-tabs>.react-tabs__tab-list .react-tabs__tab.main-tab-hd {
    padding-left: 10px;
    color: #282d6c;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    border-left: 2px solid #282d6c;
    margin-bottom: 15px;
    cursor: pointer;

}

.devloper-tab .react-tabs__tab-list .react-tabs__tab:not(.main-tab-hd):hover {
    color: #5A48FA;

}

.devloper-tab>.react-tabs>.react-tabs__tab-panel p {
    color: #73779f;
    font-size: 16px
}


.devloper-tab>.react-tabs>.react-tabs__tab-panel ul,
.devloper-tab>.react-tabs>.react-tabs__tab-panel ol {
    font-size: 16px;
    color: #73779f;
}


.devloper-tab .react-tabs__tab-panel h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    color: #04063D;
}

.devloper-tab .react-tabs__tab-panel h2 {
    font-weight: 600;
    font-size: 30px;
    color: #04063D;
    margin-top: 10px
}

.devloper-tab .list-items {
    margin-top: 30px
}

.devloper-tab .codeblock-Tabs {
    margin-top: 50px;
}

.devloper-tab>.react-tabs>.react-tabs__tab-list .react-tabs__tab.main-tab-hd:not(:first-child) {
    margin-top: 20px
}

.devloper-next-pages-links {
    padding: 30px;
}

.devloper-next-pages-links a {
    display: block;
    color: #282d6c;
    font-weight: 800;
    text-decoration: none;
    padding-bottom: 10px;
    text-transform: uppercase;
    transition: 0.3s all;
    font-size: 15px;
    line-height: 20px;
}

.devloper-next-pages-links a i {
    margin-right: 12px;
    transform: rotate(90deg);
}

.devloper-next-pages-links a:hover {
    color: #5A48FA;
}

.devloper-next-pages-links h3 {
    font-size: 29px;
    line-height: 44px;
    margin-bottom: 17px;
}

.bg-gray {
    background: #f0f0f0;
}

.gray-border {
    border-bottom: 1px solid #e8eef2;
}

.developerpay-section .columns-wrap .col-left ul li p {
    margin-top: 3px;
    font-size: 14px;
}

.developerpay-section .columns-wrap .col-left ul li {
    padding: 0;
    margin-bottom: 10px
}

.developerpay-section .columns-wrap .col-left ul li::marker {
    color: #282D6C;
}

.developerpay-section .columns-wrap .col-left ul li span {
    color: #282D6C;
    font-weight: bold;
    text-transform: capitalize
}

.developerpay-section .columns-wrap .col-left ul {
    margin-bottom: 0;
}

.api-int-section {
    margin-top: 0;
}

@media (max-width:1100px) {
    .icons-columns-wrap .col {
        width: calc(50% - 30px);
    }
}

@media (max-width:1024px) {

    .columns-wrap .col-left h2,
    .cta-col h2 {
        font-size: 32px;
        line-height: 38px;
    }

    .columns-wrap {
        gap: 15px;
    }

    .api-features-section .icons-columns-wrap .col {
        width: calc(50% - 30px);
    }

    .devloper-tab .react-tabs__tab-panel h1 {
        font-size: 35px;
    }
}

@media (max-width:800px) {
    .devloper-tab>.react-tabs>.react-tabs__tab-list {
        display: none;
    }

    .devloper-tab>.react-tabs>.react-tabs__tab-panel.react-tabs__tab-panel--selected {
        width: 100%;
        padding: 50px 20px 50px;
    }

    .devloper-next-pages-links {
        padding: 30px 0 0 0;
    }
}

@media (max-width:850px) {
    .columns-wrap {
        flex-wrap: wrap;
    }

    .columns-wrap .col {
        width: 100%;
    }

    .columns-wrap .col-left,
    .columns-wrap.reverse-column-wrap .col-left {
        padding: 0
    }


    .columns-wrap {
        gap: 50px;
        max-width: 552px;
        margin: 0 auto
    }

}

@media (max-width:767px) {
    .icons-section {
        padding: 50px 0 30px;
    }

    .img-cta-section{
        padding: 50px 0;
    }

    .cta-section,
    .api-features-section,
    .apiHero {
        padding: 50px 0;
    }

    .api-features-section .icons-columns-wrap {
        margin-top: 40px;
    }

    .devloper-tab .react-tabs__tab-panel h2,
    .devloper-next-pages-links h3 {
        font-size: 25px;
        font-size: 24px;
    }

    .devloper-tab .react-tabs__tab-panel h1 {
        font-size: 28px;
    }

    .devloper-tab .codeblock-Tabs {
        margin-top: 33px;
    }
}

@media (max-width:650px) {
    .icons-columns-wrap .col {
        width: 100%;
        margin: 0 0 20px 0;
    }

    .api-features-section .icons-columns-wrap .col {
        width: 100%;
    }
}

@media (max-width:479px) {
    .buttons-wrap {
        flex-wrap: wrap;
        gap: 10px
    }

    .columns-wrap {
        gap: 30px;
    }

    .columns-wrap .col-left h2,
    .cta-col h2 {
        font-size: 28px;
        line-height: 35px;
    }
}