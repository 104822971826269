.secondView {
    position: relative;
    max-width: 1200px;
    margin: auto;
    padding: 75px 15px;
    height: fit-content;
    background-image: url('../../../assets/backgroundPath.svg');
    background-repeat: no-repeat;
    overflow-y: visible;
    overflow-x: hidden;
}

.headingT {
    color: #04063D;
    margin: 0px;

}

.simple {
    max-width: 405px;
}

.stepDiv-container {
    display: flex;
    margin: 3vh 0vh;
}

.stepDiv-img {
    margin-right: 15px;
    padding: 0;
    max-height: 97px;
    max-width: 97px;
}

.stepText {
    margin-top: 1vh;
    line-height: 28px;
}

.blur-7 {
    background: #5A48FA;
    left: 40%;
    top: 150px;
}

.blur-8 {
    background: #4DE6FC;
    left: 50%;
    top: 200px;
}

.centered {
    margin: 0px;
}

.home-solution-cont h1,
.home-solution-cont p {
    margin-top: 10px
}


.home-solution-cont.MuiGrid-item {
    padding: 0
}

.home-solution-icons .stepDiv-container {
    margin: 0 0 60px
}

.home-solution-icons h3 {
    font-family: "SF-Pro-Display";
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 10px
}

@media only screen and (max-width: 600px) {

    /*Mobile screen displays*/
    .secondView {
        margin: 0vh;
        padding-bottom: 0px;
        padding-top: 5vh;
        height: fit-content;
        width: 100%;
        background: none;
    }

    .mobile-elevated-box {
        max-width: 220px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        margin: 0 auto;
        padding: 15px 20px;
    }

    .mobile-elevated-box p {
        background: linear-gradient(270deg, #4EE9FF 0%, #5A49FC 51.25%, #04063D 102.5%);
        ;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 500;
        font-size: 14px;
        margin: 0px;
        padding: 0px;
        font-family: 'SF Pro Display';
    }

    .center-box {
        width: fit-content;
        margin: 20px auto;
    }

    .headingT {
        text-align: center;
        max-width: 328px;
        margin: auto;
        margin-bottom: 10px;
    }

    .simple.p-18 {
        text-align: center;
        font-family: 'Montserrat';
        margin-top: 10px;
        font-feature-settings: 'salt' on, 'liga' off;
        max-width: 100%;
    }

    .stepText {
        margin: 0px;
        line-height: 20px;
    }

    .stepDiv-container {
        margin: 10px;
        margin-top: 1vh;
    }

    .stepDiv-img {
        height: 60px;
        width: 60px;
    }

    .step-Title {
        margin-bottom: 5px;
        font-size: 15px;
    }

    .centered {
        width: fit-content;
        max-width: 300px;
        margin: 0px auto;
        text-align: center;
    }

    .blur-7 {
        width: 200px;
        height: 200px;
        left: 30%;
        top: 50%;
    }

    .blur-8 {
        width: 200px;
        height: 200px;
        left: 10%;
        top: 60%;

    }

    .blur-9 {
        width: 200px;
        height: 200px;
        left: 50%;
        top: 60%;
    }

    .home-solution-cont.css-urj5pv-MuiGrid-root {
        margin-bottom: 40px;
    }
}





@media only screen and (max-width: 961px) and (min-width:600px) {

    /*Medium screen displays*/
    .stepDiv-container {
        margin: 5px;
        margin-top: 1vh;
    }

    .stepDiv-img {
        height: 13vh;
        width: 13vh;
    }

    .stepDiv-container {
        margin: 1vh;
    }

    .secondView {
        padding: 3vh;
    }


}

@media only screen and (min-width:961px)and (max-width:1337px) {

    /*Large screen displays*/
    .stepDiv-container {
        margin: 5px;
        margin-top: 1vh;
    }

    .stepDiv-img {
        height: 13vh;
        width: 10vh;
    }

    .stepDiv-container {
        margin: 1vh;
    }

    .secondView {
        padding: 3vh;
    }




}

@media (max-width:767px){
    .secondView{
        padding: 60px 15px 10px;
    }
}