.ThirdView{
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    padding:100px 15px;
    height: fit-content;
    background-image: url('../../../assets/backgroundPath2.svg');
    background-repeat: no-repeat;
    overflow-y: visible;
    overflow-x: hidden;
}
.titleContainer{
    text-align: center;
    display: flex;
    justify-content: center;
}

.titleContainer p{
    margin-top: 10px;
}
.smTitle{
    margin: 5vh 0vh;
    max-width: 445px;
}
.reasons-container{
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 95%;
    margin: 2vh auto;
    padding-top:0vh;
}
.single-reason-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    height: 325px;
    background: #FCFFFF;
    box-shadow: 0px 5px 20px rgba(95, 95, 95, 0.1);
    border-radius: 12px;
    max-width: 300px;
    gap: 30px;
    padding: 30px 25px;
    padding-bottom: 20px;
}
.single-reason-container p.body{
    text-align: left;
    font-family: 'Montserrat';
    font-style: normal;
}
.single-reason-container:hover h3{
    color: #FFFFFF;
    transition: 0.3s;
}
.single-reason-container:hover{
    background: linear-gradient(180deg, #4EE7FF 0%, #AA80F9 100%);
    border-radius: 12px;
    cursor: pointer;
}
.single-reason-container:hover .mui-right-icon{
    color: #FFFFFF;
}
.boxTitle{
    color: #282D6C;
    font-family: "SF-Pro-Display";
    font-style: normal;
    font-weight: 600;
}

.single-reason-container:hover p{
    color: white;
    transition: 0.3s;
}
.single-reason-container:hover .css-186zbgm-ThirdView{
    color: white;
    transition: 0.3s;
}
p.view-more{
    font-weight: 500;
    color:#8B8BFD;
    text-align: center;
    padding:0px;
}
.flex-div{
    display: flex;
    justify-content: space-between;
    max-width: 200px;
    margin: auto;
}
.mui-right-icon{
    color: #00C0FF ;
    margin-top: 3px;
}
.body{
    line-height: 26px;
    font-family: 'Montserrat'; 
}

.blur-11{
    top:5%;
    background:#4DE6FC;
    left: 40%;
}

@media only screen and (max-width: 600px) { 
    /*Mobile screen displays*/
    .ThirdView{
        margin-top: 44px;
        height: 100%;
    }
    .reasons-container{
        display: block;
        height: fit-content;
    }
    .single-reason-container{
        width: 90%;
        margin: auto;
        margin-top: 35px;
        max-height: 300px;
        padding: 40px 19px 27px 20px;
    }
    .smTitle{
        max-width: 272px;
        margin: 0px;
        padding: 0px;
    }
    .boxTitle{
        margin: 0px;
    }
    .body{
        font-size: 16px;
    }
    .blur-10{
height: 200px;
top: 30%;
left: 30%;
    }
    .blur-11{
        height: 200px;
        top: 30%;
        left: 30%;
    }
    }
    
    @media only screen and (max-width: 961px) and (min-width:600px){ 
    /*Medium screen displays*/

    .ThirdView{
        height: fit-content;
        margin-bottom: 15vh;
    }
    .reasons-container{
        display: grid;
        grid-template-columns:1fr 1fr;
        height: fit-content;
        width: fit-content;
        gap: 20px;
        
    }
    .single-reason-container{
        width: fit-content;
        padding: 5px;
        max-height: 300px;
        margin: 10px;
        margin-bottom: 10%;
        padding: 15px;
    }
    }
    
    @media only screen and (min-width:961px)and (max-width:1337px){
    /*Large screen displays*/

    .ThirdView{
        height: fit-content;
        margin-bottom: 15vh;
    }
    .reasons-container{
        display: grid;
        grid-template-columns:1fr 1fr;
        height: fit-content;
        gap: 20px;
        width: fit-content;
    }
    .single-reason-container{
        width: fit-content;
        padding: 25px;
        max-height: 300px;
        margin: 10px;
        margin-bottom: 10%;
    }
    }
    

    
@media (max-width:767px){
    .ThirdView{
        padding: 60px 15px;
    }
}