.policies-wrap  {
    padding: 80px 0;
  }
  
  .text-wrap h2{
   margin: 15px 0;
  }
  
  .text-wrap p{
   margin-bottom: 10px
  }
  
  .text-wrap ul li{
   color:#282D6C
  }
  
  .text-wrap ul li::marker{
   color:#282D6C 
  }
  